
function goToProductDetailCorner(brndNm, godNo, dspCtgryNo, flag, dpos, setSn) {
  var utag = '';
  var ref_myact = $('input[name="ref_myact"]').val();
  if (flag == 'recommendation') {
    utag = $('#' + flag).attr('utag') + "$tp:cl$lid:" + recoLabId + "$mid:" + recoMethodId;
  } else {
    utag = $('#' + flag).attr('utag');
    if (flag != 'SALE' && flag != "recoBrnd") {
      var setUtag = $('#' + flag).find("li[aria-selected=true] a").text();
      if (setUtag != '' && setUtag.length > 0) {
        utag += '$set:' + encodeURIComponent(setUtag);
      }
    }
  }

  if(typeof(setSn) != "undefined" && setSn != ""){
      utag += "$set:" + setSn;
  }

  if(typeof(dpos) != "undefined" && dpos != ""){
      utag += "$dpos:" + dpos;
  }

  if(ref_myact != "undefined" && ref_myact != null && ref_myact != ''){
      utag += '$ref_myact:' + ref_myact;
  }
  if((typeof givenMain) != "undefined" && (typeof givenMain.main_imid)!="undefined" && givenMain.main_imid != "" ) {
  	utag += '$imid:' + givenMain.main_imid;
  }
  var url = "/" + brndNm + "/" + godNo + "/good?" + utag + "&dspCtgryNo=" + dspCtgryNo + "&brandShopNo=" + $("#brandShopNo").val() + "&brndShopId=" + $("#lnbBrndShopId").val() + "&leftBrandNM=" + $("#leftBrandNM").val();
  location.href = url;
}

function goToProductActionLogging(flag, logTagPage, cnr, set, dpos, godTargetUrl){
    var tp = cnr;
    logTagPage = $("input[name='logTagPage']").val();
    if(tp == ''){
        var $utag = $('#' + flag);
        if( typeof($utag) != "undefined" && typeof($utag.attr('utag')) != "undefined" && $utag.attr('utag').length > 0){
            // #185353 tp에 코너번호 외 $ref_br 값 제외 요청
            if($utag.attr('utag').indexOf('ref_br') > -1){
                tp = $utag.attr('utag').substring($utag.attr('utag').indexOf("ref_cnr")+8,$utag.attr('utag').indexOf("ref_br")-1);
            }else{
                tp = $utag.attr('utag').substring($utag.attr('utag').indexOf("ref_cnr")+8);
            }
        }
    }

    var logSet = set;
    if(logSet == '') {
        var setUtag = $('#' + flag).find("li[aria-selected=true] a").text();
        if (typeof(setUtag) != 'undefined' && setUtag != '' && setUtag.length > 0) {
            set = encodeURIComponent(setUtag);
        }
    }

    action_logging({'tagPage':logTagPage,'tagArea':'cornner','tp':tp,'set':set,'dpos':dpos,'target':godTargetUrl});
}

function topSellerLoading(brndId, lang, dspCtgryNo, imgTurn, type) {
  var line = ''
  var ctgryTp = '';
  var upperDspCtgryNo = '';
  var arr = [];
  var isFilter = false;
  if (type == 'TOP_AType' || type == 'TOP_EType') {
    line = 3;
  } else if (type == 'TOP_CType') {
    line = 5;
  } else if (type == 'TOP_BType') {
    line = 9;
  } else if (type == 'TOP_CTGType') {
    line = 5;
    ctgryTp = 'CTGRY';
	  if(dspCtgryNo === '' || dspCtgryNo === undefined || dspCtgryNo === null){ // 전체 탭일 경우
        isFilter = false;
     }else{
        isFilter = true;
     }
    var sortLi = $("#mainTopCategory" + type + " li");
    upperDspCtgryNo = $(sortLi[0]).attr('upperDspCtgryNo');
	sortLi.each(function(index){
		if($(this).attr('ctgryno') != null){
			arr.push($(this).attr('ctgryno'));
		}
	});
  } else {
	line = 7; /* #91196 DType 일경우 최대 8개까지 노출 */
  }

  $.ajax({
    type: "GET",
    url: "/topSellerCorner",
    data: { "brndId": brndId, "dspCtgryNo": dspCtgryNo, "imgTurn": imgTurn, "line": line,"ctgrySectCd": givenMain.dspCtgryInfoDspCtgryCtgrySectCd, "dscr": "topSeller" + type
		, "ctgryTp": ctgryTp, "ctgryNoList": arr.toString(), "upperDspCtgryNo": upperDspCtgryNo, "isFilter" : isFilter},
    async: true,
    success: function (data) {
      if (type == 'TOP_AType') {
        drawTopSellerAType(data, lang, type);
      } else if (type == 'TOP_CType') {
        drawTopSellerCType(data, lang, type);
      } else if (type == 'TOP_BType'){
        drawTopSellerBType(data, lang, type);
      } else if (type == 'TOP_CTGType') {
      	drawTopSellerCTGType(data, lang, type);
      } else {
    	drawTopSellerEType(data, lang, type);
      }

      lazyLoad.init();
    }
  });
}

function newInLoading(brndId, lang, dspCtgryNo, imgTurn, type, isFilter) {
    let ctgryNoList = $("#newIndspCtgryList"+type).val() || '';

    if(ctgryNoList == ''){ //bo에 세팅된 카테고리 없으면 코너 미노출
        $("#newIn"+type).remove();
         return;
    }else {
        $.ajax({
            type: "GET",
            url: "/getCornerGodCnt.json",
            data: {
                  "brndId": brndId,
                  "ctgryNoList": ctgryNoList
                },
            async: true,
            success: function (data) {
                let ctgryNoArr = ctgryNoList.replace(/,\s*$/, "").split(",");
                let newInCtgryArr ="";
                if (data.godExistCtgry.length < 1) { //상품이 존재하는 카테고리가 없으면 코너미노출.
                    $("#newIn"+type).remove();
                    return;
                }

                for (let ctgryNo of ctgryNoArr) {
                    if ($.inArray(ctgryNo, data.godExistCtgry) < 0) {
                        $("#mainNewCategory"+type).find("li[ctgryno=" + ctgryNo + "]").remove();
                    }else if($.inArray(ctgryNo, data.godExistCtgry) >= 0){
                        if($("#newIndspCtgryListNEW_CTGType").length>0){
                            newInCtgryArr += ctgryNo+",";
                        }
                    }
                }

                if(newInCtgryArr != "" && newInCtgryArr!= null){
                    $("#newIndspCtgryListNEW_CTGType").val(newInCtgryArr);
                }

                let selectedNewInLi = $("#mainNewCategory"+type).find("li").get(randomArrayIndex($("#mainNewCategory"+type).find("li").length));
                if(!selectedNewInLi ){
                    selectedNewInLi = $("#mainNewCategory"+type).find("li").eq(0);
                }

                $("#mainNewCategory"+type).find("li").eq(0).click();
            }
        });
    }
}

function drawNewInCorner(brndId, lang, dspCtgryNo, imgTurn, type, isFilter){
    var productWall = $('#newin-godList' + dspCtgryNo);

    if(productWall.length > 0){
        $('#newin-godList' + dspCtgryNo).attr('style','display: display;').siblings(".godList").attr('style','display: none;');
        $("#newin_"+dspCtgryNo).attr("aria-selected","true").siblings().attr("aria-selected","false");
    }else{
        $.ajax({
           type: "GET",
           url: "/newInCorner",
           data: {
             "brndId": brndId,
             "dspCtgryNo": dspCtgryNo,
             "imgTurn": imgTurn,
             isFilter: isFilter,
             "line": 5,
             "ctgrySectCd": givenMain.dspCtgryInfoDspCtgryCtgrySectCd,
             "dscr": "newIn" + type
           },
           async: true,
           success: function (data) {
               var html = "";
               html += "<ul class='godList column3' id='newin-godList"+dspCtgryNo+"'>"
               html += "<input type='hidden' name='newinLoadYn' value='Y' />"
               html += "<h4 class='wa_hidden'>"+ givenMain.mallmain_corner_newIn_title_all +"</h4>";
               html += data;
               html += "</ul>";

               $('#newIn' + type).append(html);
               $('#newin-godList' + dspCtgryNo).attr('style','display: display;').siblings(".godList").attr('style','display: none;');
               $("#newin_"+dspCtgryNo).attr("aria-selected","true").siblings().attr("aria-selected","false");

               lazyLoad.init();
           }
        });
    }
}

//대카메인 topseller
var topIndex = 0;
function drawTopSellerCTGType(data, lang, type){
	var html = "";
	var newCtgry = "";
	var selectedLi = "";
	var headerNm = $("#mainTopCategory" + type + " li.selected").find("a").text();
	var productWall = $('#topSeller' + type + ' .godList:eq(' + topIndex + ')');
	var topsellerLoadYn = productWall.find("input[name='topsellerLoadYn']");

	if(productWall.length > 0){

		if(topsellerLoadYn.val() == 'N') {
            html += "<h4 class='wa_hidden'>" + headerNm + "</h4>";
            html += data;
        }

        productWall.siblings("ul").css("display","none");
        productWall.css("display","");
        productWall.append(html);
        topsellerLoadYn.val('Y');
	}else{
		$('#topSeller' + type + ' .godList').remove();
        html += "<ul class='godList column3'>"
		html += "<input type='hidden' name='topsellerLoadYn' value='Y' />"
		html += "<h4 class='wa_hidden'>"+ givenMain.mallmain_corner_topSeller_title_all +"</h4>";
		html += data;
		html += "</ul>";

		$('#topSeller' + type).append(html);

		if (topSellerCnrGodCnt < 1) {
			$("#topSeller" + type).remove();
		} else {
			//해당 카테고리의 상품개수 10개 이하인 탭 삭제
			checkGodCntCtgry($('#mainTopCategory'+type+' li'),topSellerCheckCntCtgryNo);
			newCtgry = $('#mainTopCategory'+type+' li');
			if($(newCtgry).length >= 2){
				newInCtgryCheck(newCtgry); //탭 카테고리 최대 10개 노출

				var html2 = "";
				for(var i=1; i<newCtgry.length; i++){
                    html2 += "<ul class='godList column3'>"
					html2 += "<input type='hidden' name='topsellerLoadYn' value='N' />";
                    html2 += "</ul>"
				}
				$('#topSeller' + type).append(html2);

				$("a", newCtgry).on("click", function(e){
                    var index = $(this).parent().index();
                    topIndex = index;
                    var dspCtgryNo = '';
                    selectedLi = newCtgry.eq(index);
                    if (selectedLi.attr('id') != "" && selectedLi.attr('id') != undefined) {
                        dspCtgryNo = $(selectedLi).attr('ctgryNo'); // 전체가 아니면 해당 대카테고리 번호 셋팅
                    }
                    productWall = $("#topSeller" + type + " .godList:eq("+topIndex+")");
                    topsellerLoadYn = productWall.find("input[name='topsellerLoadYn']");

                    if(topsellerLoadYn.val() === 'N'){
                        topSellerLoading(givenMain.brndId, lang, dspCtgryNo, $("#topSeller" + type).attr("imgTurn"), type);
                    }
                    else{
                        productWall.siblings("ul").css("display","none");
                        productWall.css("display","");
                    }
                    selectedLi.attr("aria-selected","true");
                    selectedLi.siblings().attr("aria-selected","false");
					e.preventDefault();
				});

			}else{
				$("#topSeller" + type + " .main-category").remove();
			}

		}
	}
}

// #101626 카테고리를 10개까지 노출
function newInCtgryCheck(newCaterory){
	if($(newCaterory).length > 10){ // 최대 10개 노출
		var delCnt = $(newCaterory).length - 10;
		for(var i = 0; i < delCnt; i++){
			$(newCaterory).get(10).remove();
		}
	}
}

let lagSpcltyYn = 'N';
if(givenMain.sectionNo != '' //&& givenMain.sectionNo != 'THMA33A03'
    && $("#dspCtgryNo").val() != ''
    && $("#lnbBrndShopId").val() == ''){
    lagSpcltyYn = 'Y';// 대카, 전문관일 경우 ALL이 설정되므로 cnncNo = ''로 주기 위해 설정
}

function randomArrayIndex(listCnt){
    let end = listCnt;
    let start = 1;
    let result = Math.floor((Math.random() * (end-start+1)) + start);
    return result - 1 < 0 ? 0 : result-1;
}

function getRankingParam(type, cnncCtgryNo){
    let otltYn = givenMain.sectionNo === 'THMA33A03' ? "Y" : "";
    let rankSect = type.indexOf("CLICK_RANK") > -1? "CLICK_RANK" : type.indexOf("SALE_RANK") > -1? "SALE_RANK" : "8S_RANK";
    let upperDspCtgryNo = lagSpcltyYn === 'Y' || otltYn == "Y" ? $("#dspCtgryNo").val() : "";
    let ctgryFlterCd = $("#ctgryFlterCd"+type).val() || "CTGRY_ALL"; //카테고리세트 비고란에 입력된 카테고리필터값 (없으면 CTGRY_ALL)
    let dspCtgryNo = lagSpcltyYn === 'Y' || otltYn == "Y" ? $("#dspCtgryNo").val() : "";
    
    if(otltYn == "Y"){
        ctgryFlterCd = "CTGRY_OTLT";
    }
    if(type === '8S_RANK') {
        ctgryFlterCd = $("#best-ranking-nav").find("[ctgryno='"+cnncCtgryNo+"']").attr("ctgryfltercd");
    }

    let pageSectCd = (type === '8S_RANK')? "MAIN" : "CTGRY";
    let cnrSn = $("#rankingCnrSn"+type).val();
    let cnrSetNm = $("#ranking-nav"+type).find("li[ctgryno='"+cnncCtgryNo+"']").find("a").text();

    let rankingParams = {
        "pageSectCd" : pageSectCd,
        "brndShopId" : givenMain.brndId,
        "brandShopNo" : givenMain.brandShopNo,
        "otltYn" : otltYn,
        "dspCtgryNo" : dspCtgryNo,
        "cnncCtgryNo" : cnncCtgryNo, //선택한 dspCtgryNo
        "ctgryNoList" : $("#dspCtgryList"+type).val(),
        "upperDspCtgryNo" : upperDspCtgryNo,
        "dspCnrDscr" : type,
        "ctgryFlterCd" : ctgryFlterCd,
        "rankSect" : rankSect,
        "preferAgeCd" : "ALL",
        "lagSpcltyYn" : lagSpcltyYn,
        "cnrSn" : cnrSn,
        "cnrSetNm" : cnrSetNm
    }

    return rankingParams;
}

/**
 * 랭킹코너 최초 로딩 시 상품개수 부족한 카테고리 제거 및 상품목록 조회
 * bo에 세팅된 카테고리 중 노출할 상품 6개이상 존재하는 카테고리 조회
 */
function rankingLoading(type){
    let $rankingNav = $("#ranking-nav"+type);
    let $rankingSection = $("#ranking"+type);
    let ctgryNoList = $("#dspCtgryList"+type).val();

    if(ctgryNoList == ''){ //bo에 세팅된 카테고리 없으면 카테고리영역 제거 후 전체로 조회 (카테고리세트 비고란에 입력된 카테고리필터값)
        $rankingNav.parent().remove();
        getRankingGodList(type, "", "Y");

        return;
    }else {
        $.ajax({
            type: "GET",
            url: "/getRankingCornerCtgry.json",
            data: getRankingParam(type, ""),
            async: true,
            success: function (data) {

                if (data.godExistCtgry.length < 1) { //상품이 존재하는 카테고리가 없으면 코너미노출
                    $rankingSection.remove();
                    return;
                }

                let ctgryNoArr = ctgryNoList.replace(/,\s*$/, "").split(",");
                for (let ctgryNo of ctgryNoArr) {
                    if ($.inArray(ctgryNo, data.godExistCtgry) < 0) {
                        $rankingNav.find("li[ctgryno=" + ctgryNo + "]").remove();
                    }
                }

                let selectedRankingLi = $rankingNav.find("li").get(randomArrayIndex($rankingNav.find("li").length));
                if(!selectedRankingLi ){
                    selectedRankingLi = $rankingNav.find("li").eq(0);
                }

                set_scrollX_po();
            }
        });
    }
}

/**
 * 랭킹코너 카테고리 별 상품조회
 * 한번 조회된 카테고리는 display처리
 */
function getRankingGodList(type, cnncCtgryNo, firstTab){
    let $rankingNav = $("#ranking-nav"+type);
    let $rankingSection = $("#ranking"+type);

    $rankingNav.find("li[ctgryno='"+cnncCtgryNo+"']").attr("aria-selected",true).siblings().attr("aria-selected",false);

    if($("#ranking-godList"+type+cnncCtgryNo).length > 0){
        $("#ranking-godList"+type+cnncCtgryNo).attr('style','display: display;').siblings(".godList").attr('style','display: none;');
    }else{

       $.ajax({
          type: "GET",
          url: "/rankingCorner",
          data: getRankingParam(type, cnncCtgryNo),
          async: true,
          success: function (data) {
            if(data.indexOf("li view-godno") < 0 && firstTab==='Y'){ //최초 로딩인데 상품 없으면 코너 미노출
                $rankingSection.remove();
                return;
            }

            $rankingSection.find("ul.godList").attr('style','display: none;');

            let html = "<ul class='godList column3' id='ranking-godList" + type + cnncCtgryNo + "'>";
            html += data;
            html += "</ul>";
            $rankingSection.append(html);

            if(firstTab === 'Y' && $("#ranking-godList"+type+cnncCtgryNo).find("li").length < 6){ //최초 로딩인데 상품수 부족하면 코너 미노출
                $rankingSection.remove();
                return;
            }

            lazyLoad.init();
          }

      });
    }
}

function rankingViewMore(type){
    let cnncCtgryNo = type ===  "8S_RANK" ? $("#best-ranking-nav").find("li[aria-selected='true']").attr("ctgryno") || "" : $("#ranking-nav"+type).find("li[aria-selected='true']").attr("ctgryno") || "" ;
    let rankingparams = getRankingParam(type,cnncCtgryNo);
    let upperCtgryNo = type ===  "8S_RANK" ? "" : rankingparams.upperDspCtgryNo || "SFMA41" ;
    let url = type ===  "8S_RANK" ? "/bestRankingViewMore" : "/ranking";
    let brndShopId = givenMain.brandShopNo ? givenMain.brndId : "";
    
    //utag 작업 추가
    var utag = type ===  "8S_RANK" ? $("#"+type).attr('utag') : $("#ranking"+type).attr('utag');
    url = url + "?"+ utag +"&rankSect="+rankingparams.rankSect + "&ctgryFlterCd="+rankingparams.ctgryFlterCd+"&brndShopId="+brndShopId+"&brandShopNo="+givenMain.brandShopNo+"&dspCtgryNo="+rankingparams.dspCtgryNo+"&cnncCtgryNo="+upperCtgryNo+"&otltYn="+rankingparams.otltYn;
    
    //클릭로깅 작업 추가
    var logTagPage = $("input[name='logTagPage']").val();
    var cnrSn;
    if(utag.indexOf("ref_cnr")>-1){
        cnrSn = utag.substring(utag.indexOf("ref_cnr")+8);
    }
    action_logging({'tagPage':logTagPage,'tagArea':'cornner','tp':cnrSn,'target':url});

    location.href = url;

}

function checkGodCntCtgry(ctgryTap,cntCtgryNo){
	var cntCtgryNoList = cntCtgryNo.split(',');
	for(var k=1; k<ctgryTap.length; k++){
		if($.inArray($(ctgryTap[k]).attr('ctgryno'),cntCtgryNoList) < 0){
			ctgryTap[k].parentNode.removeChild(ctgryTap[k]);
		}
	}
}
//#95379 네비게이션 개선
// tabbar 와 lnb 에서 마이브랜드 롤링
//head.jsp에 선언 되어 페이지또는 tabbar 레이어에서 공통으로사용
// ---> 탭바 속도 이슈로 탭바 레이어 주석
$(function () {
    if ( $('.brand-wrap').length ) {

    	//url parameter로 브랜드리스트 조회
    	selectUrlParams();

    	var $brandRemote = $(".brand-remote");
        var $cateStep1_li = $(".brand-categoty-step1 li");
        var $cateStep1_select = $(".type-fixed select");
        var $cateScroll = $(".brand-categoty-scroll");
        var $cateScroll_li = $(".brand-categoty-scroll li");
        // A-Z, ㄱ-ㅎ 부분.
        $('.brand-categoty-step2 dd').click(function(){
        	var brand_check_type = $('a',this).attr('data-type');
            $(this).addClass('on').siblings().removeClass('on');
            // 첫메뉴 이동
            $(".brand-remote").find('.' + brand_check_type).addClass('on').siblings().removeClass('on');
            /*if(givenBrndCtgry.isUser && givenBrndCtgry.ctgryNvgMyBrndList.length > 0){
            	$(".brand-remote").find('.' + brand_check_type).find('li').eq(0).click();
            }else{
            	$(".brand-remote").find('.' + brand_check_type).find('li').eq(2).click();
            }*/

            // 카테고리 검색일때 (사이즈가 다름) - 삭제
            // var brand_list_inner = $('.brand-wrap .brand-list-inner');
            // if ( brand_check_type == 'type-cate' ){
            //     brand_list_inner.addClass('type-cate');
            // } else {
            //     brand_list_inner.removeClass('type-cate');
            // };
            // return $brandRemoteH = $(".brand-remote").height();
        });

        // 카테고리 클릭
        $cateStep1_li.click(function(){
            var i = $(this).index();
            $(this).addClass('on').siblings().removeClass('on');
            $('option', $cateStep1_select).eq(i).prop('selected', true);
            // all 선택
            $cateScroll_li.eq(0).click();
        });
        $cateStep1_select.change(function(){
            var i = $('option:selected', $cateStep1_select).index();
            $cateStep1_li.eq(i).addClass('on').siblings().removeClass('on');
            // all 선택
            $cateScroll_li.eq(0).click();
        });
        // 스크롤 클릭
        $cateScroll_li.click(function(){
            $(this).addClass('on').siblings().removeClass('on');
            $("html,body").scrollTop(0);
            checkScrollPos();

            var brndType = $("#categoryStep1 .on").find("a").attr("brndSectCd");
            var ctgrySectCd = $(this).find("a").attr("ctgrysectcd");
            var sortType = $(".brand-categoty-step2 .on").find("a").data("lang");

            selectCategoryList(brndType,ctgrySectCd,sortType);
        });


        // 스크롤 수정
        var tabContentY = $('.tab-content').offset().top;
        $(window).on('scroll', function(){
            var scv = $(window).scrollTop();
            if (scv > 0 ) {
                if( !$('.tab-selecter').hasClass('fixed') ){
                    $('.tab-selecter').addClass('fixed');
                    $(".brand-remote").addClass('fixed').css('top', '68px');
                    checkScrollPos();
                }
            } else {
                if( $('.tab-selecter').hasClass('fixed') ){
                    $('.tab-selecter').removeClass('fixed').css('margin-top','0');
                    $(".brand-remote").removeClass('fixed').css('top', '0');
                    checkScrollPos();
                }
            };
            // 리스트 호출
            // var winH = $(window).height();
            // var secBrandH = $('.sec-brand').height();
            // if( scv + (winH * 3) > secBrandH){
            //     console.log ('리스트 호출');
            // };
        });

        // Iscroll 위치체크, 스크롤 (.on 가운데 정렬)
        var checkScrollPos = function() {
            iscrollX(  $cateScroll , '6', '45', 'false');
            var winWidth = $(window).width();
            var tabsWidth = $('.content ul',  $cateScroll).width();
            var totalSlideX = tabsWidth - winWidth;
            var selected = $('.content ul li.on',  $cateScroll);
            var selectedPos = selected.position().left;
            var selectedWidth = selected.width() / 2;
            var scrollerStart = selectedPos - (winWidth / 2) + selectedWidth + 2;
            if (scrollerStart >= totalSlideX) {
                scrollerStart = totalSlideX;
            }
            if ( selectedPos > winWidth / 2 ){
                scroller.scrollTo(scrollerStart * -1, 0, null);
            };
            checkTopSpace();
        };
        setTimeout(function(){
            checkScrollPos();
        },1000);

    };
});

function getIncludBrndList(params){
    $.ajax({
        type : "GET",
        url     : "/selectBrndNvgList",
        data : params,
        success : function(data) {
            $("#tab-content").html(data);

            $(window).scrollTop(0);

            clearBrandAlpabet();
            clearBrandKorean();

            var $sortType = params.brndSortType;
            if(params.brndSortType=="ENG"){
                $(".type-eng").addClass('on').siblings().removeClass('on');
                $sortType = $("#sortEng");
            } else if(params.brndSortType=="KOR"){
                $(".type-kor").addClass('on').siblings().removeClass('on');
                $sortType = $("#sortKor");
            }

            if(givenBrndCtgry.isUser){
            	selectMyBrandList();
            	checkMybrandListNvg();
            }

            if($sortType.find("ul[id=my] li").length == 1){
            	$("#b-pre-my").hide();
            	$("#my").hide();
            }else{
            	$("#b-pre-my").show();
            	$("#my").show();
            }
        }
    });
}

function selectCategoryList(brndType, ctgrySectCd, sortType){
	var params = {};

    if(brndType == null || brndType == ''){
    	brndType = $("#categoryStep1 .on").find("a").attr("brndSectCd");
    }

	if(brndType == "ALL"){
        params.brndShopId ="";
        params.brndSectCd="";
    }else if( brndType=='ORBR' || brndType== 'MCBR'){
        params.brndShopId =brndType;
    }else{
        params.brndSectCd = brndType;
    }

	var ctgrySectCd = $(".brand-categoty-scroll .category .on").find("a").attr("ctgrySectCd");
	if(ctgrySectCd == "ALL"){
		params.ctgrySectCd = "";
	}else{
		params.ctgrySectCd = ctgrySectCd;
	}

	if(sortType == null || sortType == ''){
    	params.brndSortType = $(".brand-categoty-step2 .on").find("a").data("lang");
    }else{
        params.brndSortType =sortType;
    }
	params.pageType="commonBrandList";
    getIncludBrndList(params);


    var queryString = window.location.search;
    var addParam = "";
    if( queryString && queryString.indexOf("glb_btn=true") > 0 && queryString.indexOf("brndType=") < 0 ){
        addParam = "&glb_btn=true";
    }


    history.replaceState(null, null,
    		location.origin+location.pathname+"?brndType="+brndType+"&ctgrySectCd="+params.ctgrySectCd+"&brndSortType="+params.brndSortType+addParam);
}

//속도 이슈로 인해 카테고리 탭만 전체조회 X
// 카테고리 마다 비동기로 호출 및 페이징 처리
/*function selectBrndCtgrySect(brndCtgrySectCd){
    var params ={};
    var step1 = $("#categoryStep1 .on").find("a").attr("brndSectCd");

    if(step1 == "ALL"){
        params.brndShopId ="";
        params.brndSectCd="";
    }else if( step1=='ORBR' || step1== 'MCBR'){
        params.brndShopId =step1;
    }else{
        params.brndSectCd = step1;
    }

    if(brndCtgrySectCd == "ALL"){
		params.ctgrySectCd = "";
	}else{
		params.ctgrySectCd = brndCtgrySectCd;
	}

    var sortType = $(".brand-categoty-step2 .on").find("a").attr("brndSortCd");
	if(sortType == 'kr'){
		params.brndSortType = "KOR";
	}else{
		params.brndSortType = "ENG";
	}

    params.pageType="commonBrandList";
    params.currentPage =$("#currentPage").val();

    if(params.currentPage!="" && params.currentPage > 1){
        params.pageType="includeCtgry";
    }

    getIncludBrndList(params);
}*/

function clickMyBrand(obj){
    var addMybrandMsg = givenBrndCtgry.addMybrandMsg;
    var removeMybrandMsg = givenBrndCtgry.removeMybrandMsg;
    var brndId = $(obj).attr("like");
    var updateMyBrndSetYn = "Y";
    if(givenBrndCtgry.isUser){
        var url = "";
        var check ="";

        if( $(obj).hasClass('on') ){
            check = "delete";
            url = "/deleteMyBrand.json";
            notiToast(removeMybrandMsg, {type: 'favorite'});
            $("[like='"+brndId+"']").removeClass("on");
            updateMyBrndSetYn = "N";
        }else{
            check = "insert";
            url = "/addMyBrand.json";
            notiToast(addMybrandMsg, {type: 'favorite'});
            $("[like='"+brndId+"']").addClass("on");
        }

        $.ajax({
            type: "post",
            url: url,
            data: { 'brndId':brndId },
            beforeSend: function (request)
            {
                var csrfToken = $("meta[name='_csrf']").attr("content");
                var csrfName = $("meta[name='_csrf_header']").attr("content");
                request.setRequestHeader(csrfName, csrfToken);
            },
            success: function(data) {
            	var url = $('li[recommendbrndshopid_brand=' + brndId + ']').find('span').attr('href');
            	var brndNm = $.trim($('li[recommendbrndshopid_brand=' + brndId + ']').find('span').attr('ctgrynm'));

            	myBrndLabelDraw(brndId, brndNm, url, check)
            	myBrandChanged(brndId, check);

            	//네이티브 앱에서 마이브랜드 추가/삭제시 app 화면도 변경할 수 있도록  app://mybrandupdate 호출 시작
            	if (setMobileAppFlag()) {
            		var userAgent = navigator.userAgent;
            		var appVersionIndex = userAgent.indexOf("app_version_");
            		var version = userAgent.substring(appVersionIndex + 12, appVersionIndex + 16).replace("/", "");
            		var updateMyBrndParam =  "?brndId=" + brndId + "&setYn=" + updateMyBrndSetYn;

            		if(IS_IOS){
            			if(/beanpole_app/gi.test(navigator.userAgent) && version >= 2.38
            					|| /8seconds_app/gi.test(navigator.userAgent) && version >= 4.37
            					|| /ssfshop_app/gi.test(navigator.userAgent) && version >= 1.51
            					|| /brooks_app/gi.test(navigator.userAgent) && version >= 1.14){
            				location.href = "app://mybrandupdate" + updateMyBrndParam;
            			}else{
            				//미만 버전에 스킴이 존재하지 않음
            			}
            		}

            		if(IS_ANDROID) {
            			if(/beanpole_app/gi.test(navigator.userAgent) && version >= 2.38
            					|| /8seconds_app/gi.test(navigator.userAgent) && version >= 4.37
            					|| /ssfshop_app/gi.test(navigator.userAgent) && version >= 1.51
            					|| /brooks_app/gi.test(navigator.userAgent) && version >= 1.14){
            				location.href = "app://mybrandupdate" + updateMyBrndParam;
            			}else{
            				//미만 버전에 스킴이 존재하지 않음
            			}
            		}
            	}
            	//네이티브 앱에서 마이브랜드 추가/삭제시 app 화면도 변경할 수 있도록  app://mybrandupdate 호출 끝


            }
        });
    }else{
        location.href="/public/member/login";
    }
}

//마이브랜드 변경 시 전체 브랜드리스트의 마이브랜드 등록 및 삭제
function myBrandChanged(brndId, check){
	if(check == "delete"){
		givenBrndCtgry.ctgryNvgMyBrndList.splice(givenBrndCtgry.ctgryNvgMyBrndList.indexOf(brndId), 1);
	}else if(check == "insert" ){
		givenBrndCtgry.ctgryNvgMyBrndList.push(brndId);
	}

	selectMyBrandList();
}

//마이브랜드 조회
function selectMyBrandList(){
	$.ajax({
        url : '/selectMybrand.json',
        type : 'get',
        success : function(data){

            var list = data.mybrand;
            var $sortType = $(".brand-categoty-step2 .on").find("a").data("lang");
            var lang = $("meta[name='pageContext.response.locale.language']").attr("content");
            $('ul[id=my]').html("");

            var nowDate = new Date();
        	nowDate = nowDate / (1000*60*60*24);

        	if(list != null && list != ''){
        		$("#b-pre-my").show();
	        	$("#my").show();

        		for(key in list){
        			var html = "<li recommendBrndShopId_brand='"+ list[key].brndId +"' recommendBrndType='myBrand'><a href='javascript:undefined;'>";
    				var loc = 'location.href="'+ list[key].outptLinkUrl +'"';

    				var brndLnchDt = list[key].brndLnchDate;
    	        	brndLnchDt = new Date(brndLnchDt.substr(0,4), parseInt(brndLnchDt.substr(4,2))-1, brndLnchDt.substr(6,2));
    	        	brndLnchDt = brndLnchDt / (1000*60*60*24);

    				if(nowDate - brndLnchDt < 30){
    					html += '<div class="icons"><span href="javascript:undefined;"><em class="new">NEW</em></span></div>';
    				}

        			if($sortType == 'ENG'){
        				html += "<div class='bname-eng'><span onclick='"+loc+"' ctgryNm='"+ list[key].brndNm +"' brndShopId='"+ list[key].brndId +"'><em>"+ list[key].brndNm +"</span></em>";
        				html += "<span href='javascript:undefined;' like='"+ list[key].brndId +"' class='favorite on' onclick='clickMyBrand($(this))'>MY 브랜드 설정</span></div>";
    					html += "<div class='bname-kor'><span href='"+ list[key].outptLinkUrl +"' ctgryNm='"+ list[key].brndNm +"' brndShopId='"+ list[key].brndId +"'><em>"+ list[key].brndNmKor +"</span></em>";
    					html += "<em class='count'> ("+ list[key].brndGodCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") +")</em></div>";

        			}else if( lang == "ko" && $sortType == 'KOR'){
        				html += "<div class='bname-eng'><span onclick='"+loc+"' ctgryNm='"+ list[key].brndNm +"' brndShopId='"+ list[key].brndId +"'><em>"+ list[key].brndNmKor +"</span></em>";
        				html += "<span href='javascript:undefined;' like='"+ list[key].brndId +"' class='favorite on' onclick='clickMyBrand($(this))'>MY 브랜드 설정</span></div>";
        				html += "<div class='bname-kor'><span href='"+ list[key].outptLinkUrl +"' ctgryNm='"+ list[key].brndNm +"' brndShopId='"+ list[key].brndId +"'><em>"+ list[key].brndNm +"</span></em>";
        				html += "<em class='count'> ("+ list[key].brndGodCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") +")</em></div>";
        			}

        			html += "</a></li>";
    				$('ul[id=my]').append(html);
        		}

        	}else{
        		$("#b-pre-my").hide();
	        	$("#my").hide();
        	}

    		//추천 조회 && 그리기
    	    if (givenBrndCtgry.isUser) {
    	        if($("#sortEng").length > 0 || $("#sortKor").length > 0 ){
    	            //successDrawRecommendIcon=false;
    	            checkCubeForUserIconSession("brand");
    	        }
    	    }
        }
    })
}

/**
 * APP mybrand 추가/삭제 후 callback
 */
function updateMyBrnd(brndId, setYn) {

	var check = (setYn == "Y") ? check="insert" : check="delete";

	if(check == "delete"){
		myBrndLabelDraw(brndId, null, null, check);
		$("[like='"+brndId+"']").removeClass("on");
    }else{
    	var url;
    	var brndNm;
    	var topBrndDrawCheck = givenOption.lnbCategorySetBrndShopId === brndId;
    	if(topBrndDrawCheck){
    		url = $(".brand-count-area .brand > a").attr('href');
        	brndNm = $("input[name=includeLeftBrndShopNm]").val();
		}else{
			url = $('[lnbmiddlemybrndid=' + brndId + ']').parent().attr('href');
			brndNm = $.trim($('[lnbmiddlemybrndid=' + brndId + ']').next().text());
		}
    	myBrndLabelDraw(brndId, brndNm, url, check);
    	$("[like='"+brndId+"']").addClass("on");
    }
	myBrndLabelDrawForOtherPage(brndId, check);

	var url = $('li[recommendbrndshopid_brand=' + brndId + ']').find('span').attr('href');
	var brndNm = $.trim($('li[recommendbrndshopid_brand=' + brndId + ']').find('span').attr('ctgrynm'));

	myBrndLabelDraw(brndId, brndNm, url, check);
	myBrandChanged(brndId, check);
}

function clearBrandAlpabet() {
    $("#sortEng ul").each(function(){
        if($(this).find('li').length < 1){
            $("#b-pre-"+$(this).attr("id")).remove();
            $(this).remove();
            $(".b-pre-"+$(this).attr("id")).remove();
        }
    });
}

function clearBrandKorean() {
    $("#sortKor ul").each(function(){
        if($(this).find('li').length < 1){
            $("#b-pre-"+$(this).attr("id")).remove();
            $(this).remove();
            $(".b-pre-"+$(this).attr("id")).remove();
        }
    });
}

function checkMybrandListNvg(){
	for(var i=0; i < givenBrndCtgry.ctgryNvgMyBrndList.length; i++){
        var brndId = givenBrndCtgry.ctgryNvgMyBrndList[i];
         if($("[like='"+brndId+"']").length) {
             $("[like='" + brndId + "']").addClass("on");
         }
     }
}

/*function goBrandMain(obj) {
    var brndNm = $(obj).attr("ctgryNm") + "_" + $(obj).attr("brndShopId");
    var brndShopId = $(obj).attr("brndShopId");

    $.ajax({
        url : "/selectDspCtgryBrand.json",
        type : "get",
        data : {"dspBrndId":brndShopId},
        success : function(data){
            goBrandLink(data,brndNm);
        }
    })
}*/

function goBrandLink(data, brndNm){
    var brandShopNo = data.dspCtgryBrand.dspCtgryNo;
    var ctgryOutptTpCd = data.dspCtgryBrand.ctgryOutptTpCd;
    var outptSectCd = data.dspCtgryBrand.outptSectCd;
    var outptLinkUrl = data.dspCtgryBrand.outptLinkUrl;
    var dspBrndId = data.dspCtgryBrand.dspBrndId;
    var url = "";

    brndNm = brndNm.toUpperCase();

    if(outptSectCd == 'LINK' ){
        //앱에서 window.open 문제 있어서 무조건 location.href 로 변경
        location.href = outptLinkUrl;
    }else{
        // BRAND MALL landing
        if(brandShopNo == "BDMA01"){
            //빈폴 메인
            $("#lnbDspCtgryForm #dspCtgryNo").val("");
            $("#lnbDspCtgryForm #brandShopNo").val(brandShopNo);
            $("#lnbDspCtgryForm #etcCtgryNo").val("");
            $("#lnbDspCtgryForm #lnbBrndShopId").val(dspBrndId);
            $("#lnbDspCtgryForm #leftBrandNM").val(encodeURI(brndNm));
            url = beanpoleMainPageUrl();
        }else if(brandShopNo == "BDMA09"){
            //비이커 메인
            $("#lnbDspCtgryForm #dspCtgryNo").val("");
            $("#lnbDspCtgryForm #brandShopNo").val(brandShopNo);
            $("#lnbDspCtgryForm #etcCtgryNo").val("");
            $("#lnbDspCtgryForm #lnbBrndShopId").val(dspBrndId);
            $("#lnbDspCtgryForm #leftBrandNM").val(encodeURI(brndNm));
            url = beakerMainPageUrl();
        }else{
            $("#lnbDspCtgryForm #dspCtgryNo").val("");
            $("#lnbDspCtgryForm #brandShopNo").val(brandShopNo);
            $("#lnbDspCtgryForm #etcCtgryNo").val("");

            // 브랜드샵 메인
            $("#lnbDspCtgryForm #lnbBrndShopId").val(dspBrndId);
            $("#lnbDspCtgryForm #leftBrandNM").val(encodeURI(brndNm));
            url = brandShopMainUrl(brandShopNo, encodeURI(brndNm));
        }

        $('#lnbDspCtgryForm').attr('action', url);
        var frm = $('#lnbDspCtgryForm');

        frm.submit();
    }
}

function getUrlParams() {
    var params = {};
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str, key, value) { params[key] = value; });
    return params;
}

function selectUrlParams(){
	var retParams = getUrlParams();
	var brndType = "";
	var ctgrySectCd = "";
	var brndSortType = "";

	//디폴트: SSF공식브랜드/ALL/A-Z
	if(retParams.brndType != null && retParams.brndType != ""){
		brndType = retParams.brndType;
	}else{
		brndType = "SSF_OFFCL_BRND";
	}

	if(retParams.ctgrySectCd != null && retParams.ctgrySectCd != ""){
		ctgrySectCd = retParams.ctgrySectCd;
	}else{
		ctgrySectCd = "ALL";
	}

	if(retParams.brndSortType != null && retParams.brndSortType != ""){
		brndSortType = retParams.brndSortType;
	}else{
		brndSortType = "ENG";
	}

	$("#"+brndType+"_navi").addClass("on");
	$("#selectCategoryStep1").find("option[brndsectcd='"+ brndType +"']").prop('selected', true);
	$(".brand-categoty-scroll li[cd='"+ctgrySectCd+"']").addClass("on");
	$(".brand-categoty-step2 dd").find("a[data-lang='"+brndSortType+"']").parent().addClass("on");

	selectCategoryList(brndType, ctgrySectCd, brndSortType);
}

var sectionCtgryNo = givenMain.sectionCtgryNo;
if (sectionCtgryNo === givenMain.displayEnumOTLTCTG) {
  sectionCtgryNo = '';
}
if(givenMain.ctgryMainYn == 'Y'){
	sectionCtgryNo = '';
}
CMN_MyWishInitAll();//#116680. 2020-10-20 상품목록 찜 기능
function initCnr() {
    givenMain.cnr.forEach(function (cnr) {
        if (cnr.isTopSeller) {
          setTimeout(function () {
            if ($("#topSeller" + cnr.cnrDspCnrDspCnrDscr).length > 0) {
              topSellerLoading(givenMain.brndId, givenOption.language, sectionCtgryNo, givenMain.firstDspCnrSetDscr, cnr.cnrDspCnrDspCnrDscr);
            }
          }, 0);
        }

        if (cnr.isNewIn) {
          setTimeout(function () {
            if ($("#newIn" + cnr.cnrDspCnrDspCnrDscr).length > 0) {
              newInLoading(givenMain.brndId, givenOption.language, sectionCtgryNo, givenMain.firstDspCnrSetDscr, cnr.cnrDspCnrDspCnrDscr);
            }
          }, 0);

        }

        if (cnr.isClickRank) {
            setTimeout(function () {
                if ($("#ranking" + cnr.cnrDspCnrDspCnrDscr).length > 0) {
                    rankingLoading(cnr.cnrDspCnrDspCnrDscr);
                }
            }, 0);
        }

        if (cnr.isSaleRank) {
            setTimeout(function () {
                if ($("#ranking" + cnr.cnrDspCnrDspCnrDscr).length > 0) {
                    rankingLoading(cnr.cnrDspCnrDspCnrDscr);
                }
            }, 0);
        }
        if (cnr.isDiver) {
            setTimeout(function () {
                if ($("#diver" + cnr.cnrDspCnrDspCnrDscr).length > 0) {
                    setMainObserver("diverDIVER_STYLE_BANNER");
                }
            }, 0);
        }
    });

    //<!-- #166989 [퍼블_MO] 컨텐츠 팝업 UI 변경 및 BO 팝업관리 개선 -->
    if($('#contentsPopupMoCnr').length > 0){

        $(document).ready(function () {
            var exe_mainContPopup = function () {
                if ($('.mainContPopup .swiper-slide').length < 2) {
                    $('.mainContPopup .swiper-pagination').remove();
                    return false;
                }
                var mainContPopup = new Swiper(".mainContPopup .swiper", {
                    direction: "horizontal",
                    slidesPerView: 1,
                    spaceBetween: 0,
                    loop: $("#contentsPopupMoCnr .swiper-slide").length > 1,
                    autoplay: {
                        delay: 4000,
                        disableOnInteraction: false
                    },
                    pagination: {
                        el: ".mainContPopup .swiper-pagination",
                        type: "fraction"
                    },
                    on: {
                        init: function (swiper) {
                            lazyLoadSwiper.init(this);
                        }
                    }
                });
            };

            let contentPopupCloseToDay = getCookie('contentPopupCloseToDay');
            if (!contentPopupCloseToDay) {
                if ($(".mainContPopup").length > 0) {
                    exe_mainContPopup();
                }
                var that = $('.modal-layer.mainContPopup').openLayer({top: 'auto'});
                action_logging({
                    'tagStep': 'popupDisplay',
                    'tagArea': 'contents',
                    'tagPage': location.pathname.replace(/^\//, '')
                });
                $(that).prev('.modal-dim').on('click', function () {
                    action_logging({
                        'tagStep': 'popupClick',
                        'tagArea': 'contents',
                        'tagPage': location.pathname.replace(/^\//, ''),
                        'tp': 'close'
                    });
                });
            }
            $("#contentPopupCloseToDay").on('click', function () {
                setCookie('contentPopupCloseToDay', 'Y', 1);
                $('#contentsPopupMoCnr').closest('.modal-layer').closeLayer();
                action_logging({
                    'tagStep': 'popupClick',
                    'tagArea': 'contents',
                    'tagPage': location.pathname.replace(/^\//, ''),
                    'tp': 'closeToday'
                });
            });

        });
    }
}




function goImgSearchGod(godNo) {
  //window.localStorage.setItem("URL_imgsearch_clicked", document.URL);
  location.href = "/public/search/imgsearch/" + godNo + "/similarGoodslist";
}
//  app download layer
//  몰 MAIN / 국문일 경우 노출
if (givenOption.lnbCategorySetBrndShopId === "" && givenOption.isKo) {
  $(document).ready(function () {
    $("#naverCheckbox").click(function () {
      popOut();
    });
  });

  // 팝업 닫기
  function popOut() {
    // app download layer 24시 기준 재노출
    setCookie('todayAppDownFlag', 'Y', 1);
  }
}

// app download layer
// 앱푸쉬(광고성) 수신동의 프로모션

if (givenMain.appPushPrm) {
  $(document).ready(function () {
    if (setMobileAppFlag()) {	//SSF Shop App 인 경우
          if(/ssfshop_app/gi.test(navigator.userAgent.toLowerCase())) {   //SSF Shop App 인 경우
              getCheckAllSsfNtcnYn();
          } else if(/beanpole_app/gi.test(navigator.userAgent.toLowerCase())) {   //빈폴 Shop App 인 경우
              getCheckAllBeanpoleNtcnYn();
          } else if(/brooks_app/gi.test(navigator.userAgent.toLowerCase())) {   //브룩스 Shop App 인 경우
              getCheckAllBrooksNtcnYn();
          } else if(/8seconds_app/gi.test(navigator.userAgent.toLowerCase())) {   //8S Shop App 인 경우
              getCheckAllNtcnYn();
          }
      }
  });

    //WEB -> APP SSF 앱푸시 수신동의 여부 조회 처리요청
    function getCheckAllSsfNtcnYn(){
        //TO-DO : 앱환경에서 주석해제
      var userAgent = navigator.userAgent;
      var appVersionIndex = userAgent.indexOf("app_version_");
      var version = userAgent.substring(appVersionIndex + 12, appVersionIndex + 16).replace("/", "");
      if(IS_IOS) {
        if (/ssfshop_app/gi.test(navigator.userAgent) && version < 1.80) {
          loadIntentAction("app://getbarcode_ssf_event");
        } else if (/ssfshop_app/gi.test(navigator.userAgent) && version >= 1.80) {
          window.webkit.messageHandlers.getbarcode_ssf_event.postMessage({});
        } else {
          loadIntentAction("app://getbarcode_ssf_event");
        }
      } else {
        loadIntentAction("app://getbarcode_ssf_event");
      }

        //개발자 테스트 코드
       /* var device_id = 'e45a542524f99315';
        $.ajax({
            type 	 : "GET",
            dataType : "json",
            data : {appId : device_id},
            async : false,
            url		 : '/public/interface/getbarcodeSsfEvent.json',
            success  : function(data) {
                fn_getBarcodeSsfEvent(data);
            },
            error: function( xhr, status, err ) {
                alert(xhr.responseText);
            }
        });*/
    }

    //WEB -> APP 빈폴 앱푸시 수신동의 여부 조회 처리요청
    function getCheckAllBeanpoleNtcnYn(){
        //TO-DO : 앱환경에서 주석해제
      var userAgent = navigator.userAgent;
      var appVersionIndex = userAgent.indexOf("app_version_");
      var version = userAgent.substring(appVersionIndex + 12, appVersionIndex + 16).replace("/", "");
      if(IS_IOS) {
        if (/beanpole_app/gi.test(navigator.userAgent) && version < 2.70) {
          loadIntentAction("app://getbarcode_beanpole_event");
        } else if (/beanpole_app/gi.test(navigator.userAgent) && version >= 2.70) {
          window.webkit.messageHandlers.getbarcode_beanpole_event.postMessage({});
        } else {
          loadIntentAction("app://getbarcode_beanpole_event");
        }
      } else {
        loadIntentAction("app://getbarcode_beanpole_event");
      }

        //개발자 테스트 코드
        /* var device_id = 'e45a542524f99315';
         $.ajax({
             type 	 : "POST",
             dataType : "json",
             data : {appId : device_id},
             async : false,
             url		 : '/public/interface/getbarcodeBeanpoleEvent.json',
             success  : function(data) {
                 fn_getBarcodeBeanpoleEvent(data);
             },
             error: function( xhr, status, err ) {
                 alert(xhr.responseText);
             }
         });*/
    }

    //WEB -> APP 8S 앱푸시 수신동의 여부 조회 처리요청
    function getCheckAllNtcnYn(){
        //TO-DO : 앱환경에서 주석해제
      var userAgent = navigator.userAgent;
      var appVersionIndex = userAgent.indexOf("app_version_");
      var version = userAgent.substring(appVersionIndex + 12, appVersionIndex + 16).replace("/", "");
        if(IS_IOS) {
          if(/8seconds_app/gi.test(navigator.userAgent.toLowerCase()) && version < 4.70) {
            loadIntentAction("app://getBarcodeEvent");
          }else if(/8seconds_app/gi.test(navigator.userAgent) && version >= 4.70) {
            window.webkit.messageHandlers.getBarcodeEvent.postMessage({});
          }else {
            loadIntentAction("app://getBarcodeEvent");
          }
        }else{
            loadIntentAction("app://getbarcode_8sec_event");
        }
        //개발자 테스트 코드
        /*var device_id = 'e45a542524f99315';
        $.ajax({
            type 	 : "GET",
            dataType : "json",
            data : {appId : device_id},
            async : false,
            url		 : '/public/interface/getbarcodeEvent.json',
            success  : function(data) {
                fn_getBarcodeEvent(data);
            },
            error: function( xhr, status, err ) {
                alert(xhr.responseText);
            }
        });*/
    }

    //WEB -> APP 브룩스 앱푸시 수신동의 여부 조회 처리요청
    function getCheckAllBrooksNtcnYn(){
        //TO-DO : 앱환경에서 주석해제
      var userAgent = navigator.userAgent;
      var appVersionIndex = userAgent.indexOf("app_version_");
      var version = userAgent.substring(appVersionIndex + 12, appVersionIndex + 16).replace("/", "");
      if(IS_IOS) {
        if (/brooks_app/gi.test(navigator.userAgent) && version < 1.50) {
          loadIntentAction("app://getbarcode_brooks_event");
        } else if (/brooks_app/gi.test(navigator.userAgent) && version >= 1.50) {
          window.webkit.messageHandlers.getbarcode_brooks_event.postMessage({});
        } else {
          loadIntentAction("app://getbarcode_brooks_event");
        }
      } else {
        loadIntentAction("app://getbarcode_brooks_event");
      }

        //개발자 테스트 코드
        /*var device_id = 'e45a542524f99315';
        $.ajax({
            type 	 : "GET",
            dataType : "json",
            data : {appId : device_id},
            async : false,
            url		 : '/public/interface/getbarcodeBrooksEvent.json',
            success  : function(data) {
                fn_getBarcodeBrooksEvent(data);
            },
            error: function( xhr, status, err ) {
                alert(xhr.responseText);
            }
        });*/
    }

  //APP -> WEB 앱푸시 수신동의 여부 조회 처리요청 callback
  function fn_getBarcodeSsfEvent(jsonData) {
      var deviceId = jsonData.deviceId;
      setCookie("DEVICE_ID", deviceId);
  }

    //APP -> WEB 빈폴 앱푸시 수신동의 여부 조회 처리요청 callback
    function fn_getBarcodeBeanpoleEvent(jsonData) {
        var deviceId = jsonData.deviceId;
        setCookie("DEVICE_ID", deviceId);
    }

    //APP -> WEB 8S 앱푸시 수신동의 여부 조회 처리요청 callback
    function fn_getBarcodeEvent(jsonData){
        var deviceId = jsonData.deviceId;
        setCookie("DEVICE_ID", deviceId);
    }

    //APP -> WEB 브룩스 앱푸시 수신동의 여부 조회 처리요청 callback
    function fn_getBarcodeBrooksEvent(jsonData){
        var deviceId = jsonData.deviceId;
        setCookie("DEVICE_ID", deviceId);
    }

    function fn_useBarcodeSsfEvent(jsonData){ //IOS는 CALL BACK 이 잘못개발됨
        fn_getBarcodeSsfEvent(jsonData);
    }

    function fn_useBarcodeBeanpoleEvent(jsonData){ //IOS는 CALL BACK 이 잘못개발됨
        fn_getBarcodeBeanpoleEvent(jsonData);
    }

    function fn_useBarcodeEvent(jsonData){ //IOS는 CALL BACK 이 잘못개발됨
        fn_getBarcodeEvent(jsonData);
    }

    function fn_useBarcodeBrooksEvent(jsonData){ //IOS는 CALL BACK 이 잘못개발됨
        fn_getBarcodeBrooksEvent(jsonData);
    }

}

function getDiffDaysForCookieEndDt(yyyymmdd) {
  var defaultDays = 365;
  if( !yyyymmdd || yyyymmdd.length !== 8) {
    return defaultDays;
  }

  var sYear = yyyymmdd.substring(0,4);
  var sMonth = yyyymmdd.substring(4,6);
  var sDate = yyyymmdd.substring(6,8);

  if( sYear === 9999){
    return defaultDays;
  }

  if( Number(sMonth) < 1 || Number(sMonth) > 12){
    return defaultDays;
  }

  if( Number(sDate) < 1 || Number(sDate) > 31){
    return defaultDays;
  }

  var today = new Date();
  var endDt = new Date(Number(sYear), Number(sMonth)-1, Number(sDate));

  var diffDays = Math.ceil((endDt.getTime() - today.getTime()) / (1000 * 3600 * 24));
  if( diffDays < 1 || diffDays > 730){
    return defaultDays;
  }

  return diffDays + 1;
}

function isElementInViewport (el) {

    // Special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
}

function topBnfCookie(mainTopbannerId, closeId, dspEndDt) {
  if ($('#chk_' + closeId).prop('checked')) {
    setCookie(closeId, 'Y', getDiffDaysForCookieEndDt(dspEndDt));
  }
  $('#' + closeId).remove();

  if( $("#"+mainTopbannerId).find(".topbanner-box").length < 1 ){
    $('#' + mainTopbannerId).remove();
  }
}

function goLogin(flag) {
  if (flag == 'm') {
    location.href = "/secured/mypage/certifyMember";
  } else {
    location.href = "/public/member/login?loginTarget=" + encodeURIComponent(location.href);
  }
}

// <!-- SR#147549.메인 Top Brand 영역 아웃링크(토리버치) 클릭 로깅 추가 요청  -->
function fnClickBstBrnd(url, utag){
    if(url.includes('toryburch.co.kr')) {
        action_logging({outlink:"toryburch&"+utag});
    }
}


// <!-- 추천 상품이 노출되는 경우 추천업체 및 추천 상품 리스트를 WiseLog에 로깅하기 위해 n_click_logging으로 로깅.. -->
if (!!givenOption.utag_base) {
  n_click_logging('impression', '', givenOption.utag_base + '$tp:im$attr_prds:' + givenOption.utag_prds);
}
if (!!givenOption.utag_base) {
  n_click_logging('impression', '', givenOption.utag_base + '$tp:im$attr_prds:' + givenOption.utag_prds);
}

if (givenOption.isAbTest) {
  n_click_logging('display_ab_test', '',
    'ab_test_sn:' + givenOption.dtoAbTestSn
    + '$ab_rev_sn:' + givenOption.dtoRevSn
    + '$ab_rev_trn_sn:' + givenOption.dtoRevCpstTurn);
}
//<!-- 개인화 캠페인이 적용된 페이지의 경우 n_click_logging으로 로깅 -->
if (givenMain.isCmpg) {
  givenMain.cmpgLogInfo.forEach(function (log) {
    n_click_logging('display_cmpg', '', log);
  });
}

//#76544 연말정산 이벤트 - (main alert 개발건)
if (givenMain.hasMainOrdCpnEvtNo) {
	setTimeout(function () {
		try {
			// 최초 1번만 연말정산 쿠폰 발급내용 노출함
			if(getCookie('mainOrderCpnFlag') != 'Y') {
				setCookie("mainOrderCpnFlag", "Y", 90);
				commonAlert2("<span style='display:block; text-align: center;'>고객님께 감사의 마음을 담아 준비한<br>룰렛 이벤트로 100% 혜택을 받으세요!</span>", function() {
					location.href = "/public/inflow/partnerGate/serviceId/24010?nextUrl=/event/" + givenMain.mainOrdCpnEvtNo + "/view";
				}, true);
			}
    	} catch(e) {}
	}, 0);
}

//#138095 쿠폰 지급 알람 개발 건
if(givenMain.eventCouponAlertYn){
    try{
        if(getCookie('eventCouponAlertYn') != 'Y'){
            $.ajax({
                url: '/public/promotion/event/eventCouponAlertYn.json',
                type: 'GET',
                success: function (data) {
                    if(data.moveUrlMb && data.alertMsg){
                        setCookie("eventCouponAlertYn", "Y", 40);

                        commonConfirm2("<span style='display:block; text-align: center;'>"+data.alertMsg+"</span>", function() {
                          location.href = data.moveUrlMb;
                        }, true);
                    }
                },
                error: function (data) { }
            });
        }
    }catch (e) { }
}

$('#lstPieceMore').click(function(e) {
  lstPieceLoading();
  e.preventDefault();
});

// tab 카테고리 배너 탭 클릭시
function tabCtgryBannerClick(_this){
    var cnrSetSn = $(_this).find("input[name='cnrSetSn']").val();
    var cnrDscr = $(_this).find("input[name='cnrDscr']").val();
    var tabCount = parseInt($(_this).find("input[name='tabCount']").val()) + 1;
    var mainPageNm = $("input[name='mainPageNm']").val();

    $.ajax({
        type : "GET",
        url : "/getTabDataMore",
        data : {"cnrSetSn" : cnrSetSn, "cnrDscr" : cnrDscr, "tabCount" : tabCount, "tmplatScKey" : mainPageNm},
        success : function (data) {
            $("#tabCList").empty();
            $("#tabCList").html(data);

            $(_this).attr("aria-selected","true");
            $(_this).siblings().attr("aria-selected","false");
        
            lazyLoad.init();
        }
    });
}

// tab 다이버 배너 탭 클릭시
function tabDiverBannerClick(_this){
    var setNm = $(_this).find("input[name='setNm']").val();
    var cnrDscr = $(_this).find("input[name='cnrDscr']").val();
    var tabCount = parseInt($(_this).find("input[name='endTabCnt']").val()) + 1;
    var mainPageNm = $("input[name='mainPageNm']").val();
    var cnrSetSn = $(_this).find("input[name='cnrSetSn']").val();

    if(!isNaN(tabCount)) {
        $.ajax({
            type: "GET",
            url: "/getTabDataMore",
            data: {
                "cnrSetSn": cnrSetSn,
                "setNm": setNm,
                "cnrDscr": cnrDscr,
                "tabCount": tabCount,
                "tmplatScKey": mainPageNm
            },
            success: function (data) {
                $(".storyList.column3").empty();
                $(".storyList.column3").html(data);

                $(_this).attr("aria-selected", "true");
                $(_this).siblings().attr("aria-selected", "false");

                lazyLoad.init();
            }
        });
    }
}

// [SSF] MO_2022_TOP 브랜드 더보기
var topBrndCurrentPage = $("input[name='brndFirstPage']").val();
var topBrndList = [];
function reloadBrandMore(cnrDscr,brandCnrSetSize){
    var firstPage = $("input[name='brndFirstPage']").val();
    var mainPageNm = $("input[name='mainPageNm']").val();
    var brndCnrSetSnLength = $("input[name='topBrndSetSn[]']").length;
    var brndCnrSetSn = "";
    for(var i=0;i<brndCnrSetSnLength;i++){
        topBrndList[i] = $("input[name='topBrndSetSn[]']")[i].value;
    }

    if((firstPage == 0 && topBrndCurrentPage == brandCnrSetSize-1) || (firstPage == 1 && topBrndCurrentPage == brandCnrSetSize)){
        topBrndCurrentPage = Number($("input[name='brndFirstPage']").val());
        if(firstPage==1){
            brndCnrSetSn = topBrndList[topBrndCurrentPage-1];
        } else {
            brndCnrSetSn = topBrndList[topBrndCurrentPage];
        }
    }else{
        topBrndCurrentPage++;
        if(firstPage==1){
            brndCnrSetSn = topBrndList[topBrndCurrentPage-1];
        } else {
            brndCnrSetSn = topBrndList[topBrndCurrentPage];
        }
    }

    $.ajax({
        type : "GET",
        url : "/getTabDataMore",
        data : {"cnrSetSn" : brndCnrSetSn, "cnrDscr" : cnrDscr, "index" : topBrndCurrentPage, "tabCount" : topBrndCurrentPage+1, "tmplatScKey" : mainPageNm},
        success : function (data) {
            $("#topBrandList").empty();
            $("#topBrandList").html(data);

            if((firstPage == 0 && topBrndCurrentPage == brandCnrSetSize) || (firstPage == 1 && topBrndCurrentPage-1 == brandCnrSetSize)){
                $("#brndReload").find("span").text("1/"+brandCnrSetSize);
            }else{
                if(firstPage == 0){
                    $("#brndReload").find("span").text((topBrndCurrentPage+1)+"/"+brandCnrSetSize);
                }else{
                    $("#brndReload").find("span").text(topBrndCurrentPage+"/"+brandCnrSetSize);
                }
            }
                
            lazyLoad.init();
        }
    });
}

// [SSF] MO_2022_룩북 더보기
function reloadLookbookdMore(cnrDscr,lookbookCnrSetSize){
    var mainPageNm = $("input[name='mainPageNm']").val();
    var lookbookCurrentPage = Number($("input[name='lookbookCurrentPage']").val());
    
    // 다음 코너세트 랜덤 뽑기
    var nextSetSn = shuffleLookBook();

    $.ajax({
        type : "GET",
        url : "/getTabDataMore",
        data : {"cnrDscr" : cnrDscr, "cnrSetSn" : nextSetSn, "tabCount" : lookbookCurrentPage, "tmplatScKey" : mainPageNm},
        success : function (data) {
            $(".lookbookItem").empty();
            $(".lookbookItem").html(data);
            
            if(lookbookCurrentPage == lookbookCnrSetSize){
                $("input[name='lookbookCurrentPage']").val(1);
                $("#lookbookReload").find("span").text("1/"+lookbookCnrSetSize);
            }else{
                $("input[name='lookbookCurrentPage']").val(lookbookCurrentPage+1);
                $("#lookbookReload").find("span").text((lookbookCurrentPage+1)+"/"+lookbookCnrSetSize);
            }
        
            lazyLoad.init();
        }
    });
}

var lookbookRandomIdx;
var lookbookIdx;
var lookbookList = [];
var lookbookCopy = [];
function shuffleLookBook(){
    var cnrSetSnLength = $("input[name='lookbookSetSn[]']").length;
    var nextSetSn;
    
    // 룩북 코너 세트 번호 저장
    for(var i=0;i<cnrSetSnLength;i++){
        lookbookList[i] = $("input[name='lookbookSetSn[]']")[i].value;
    }
    
    // 룩북 더보기 첫 클릭 or 코너세트 더보기 클릭으로 모두 불러온 후
    if(lookbookCopy.length == 0){
        lookbookCopy = lookbookList.slice();
    }
    
    // 더보기 처음 클릭시 index 0번째 코너 번호 제외(화면에 보이는)
    if(lookbookRandomIdx == undefined){
        lookbookCopy.splice(0,1);
    }
    
    lookbookRandomIdx = Math.floor(Math.random() * lookbookCopy.length);
    // 다음에 노출 될 코너세트
    nextSetSn = lookbookCopy[lookbookRandomIdx];
    
    // 다음에 노출 될 코너 번호 lookbookCopy에서 제외
    lookbookCopy.splice(lookbookRandomIdx,1);
 
    return nextSetSn;
}

function lookbookOtherImg(index,_this){
    var captionIdx = "caption"+index;

    $("[data-index="+captionIdx+"]").css("display","");
    $("[data-index*='caption']").not("[data-index="+captionIdx+"]").css("display","none");
}

function updateMyBrand(id, myBrndCount, _this){

	if(!givenBrndCtgry.isUser){
          event.stopImmediatePropagation();
		var url = location.href;
		location.href = "/public/member/login?loginTarget="+encodeURIComponent(url);
		return;
	}

    var currentCnt = parseInt(myBrndCount); // 현재 브랜드 하트 갯수
    var actionType = "";
    var	url = "";

    if($(_this).attr('aria-pressed') == "true"){ // 찜 목록에서 해제
        actionType = "D";
        url = "/deleteMyBrand.json";
    }else{ // 찜 목록에 추가
        actionType = "C";
        url = "/addMyBrand.json";
    }

    $.ajax({
        type: "POST",
        url: url,
        data: "brndId=" + id,
        beforeSend: function (request){
            var csrfToken = $("meta[name='_csrf']").attr("content");
            var csrfName = $("meta[name='_csrf_header']").attr("content");
            request.setRequestHeader(csrfName, csrfToken);
        },
        success: function(data) { // 찜 해제
            var html = '';
            if(actionType === "D"){
                currentCnt = currentCnt-1;
                if(currentCnt > 999){
                    html += '<button class="like white" aria-pressed="false" aria-label="좋아요" onclick="javascript:updateMyBrand(\''+id+'\',\''+currentCnt+'\', this);"></button>999+';
                }else{
                    html += '<button class="like white" aria-pressed="false" aria-label="좋아요" onclick="javascript:updateMyBrand(\''+id+'\',\''+currentCnt+'\', this);"></button>'+currentCnt;
                }
                $(".brandIntro .gpa").html(html);
            }else if(actionType === "C" && data.result > 0){  // 찜하기
                currentCnt = currentCnt+1;
                if(currentCnt > 999){
                    html += '<button class="like white" aria-pressed="true" aria-label="좋아요" onclick="javascript:updateMyBrand(\''+id+'\',\''+currentCnt+'\', this);"></button>999+';
                }else{
                    html += '<button class="like white" aria-pressed="true" aria-label="좋아요" onclick="javascript:updateMyBrand(\''+id+'\',\''+currentCnt+'\', this);"></button>'+currentCnt;
                }
                $(".brandIntro .gpa").html(html);
            }else{
                console.log("오류가 발생하였습니다.");
            }
            toggleButton_like();
        },
        error: function (e) {
            console.log(e.responseText);
        }
    });
}

// [SSF]  MO_2022_브랜드 룩북
var lookbookSet = [];
// [SSF] MO_2022_혜택 띠배너
var sashBanner = [];
// [SSF] MO_2022_상품리스트(더보기) 01-04
var categoryBrandSet = [];


// #174094 [SSF] MO_2022_상품리뷰코너 관련 js START
const reviewShowCount = 3;
/**
 * [SSF] MO_2022_상품리뷰코너
 * [button] 상품 리뷰 탭 선택
 */
function moveReviewTab(obj){
    //선택한 탭 ID
    var godReviewTabId = $(obj).parent().attr("godReviewTabId");
    $("#ul_reviewShow").empty();
    var godReviewlist = $("#"+godReviewTabId).children("li");
    for(let i=0; i < reviewShowCount; i++){
        if(i < godReviewlist.length){
            $("#ul_reviewShow").append($(godReviewlist[i])[0].outerHTML);
        }
    }

    if(godReviewlist.length > reviewShowCount){
        $("#btn_reloadGodEvlList").show();
    }else{
        $("#btn_reloadGodEvlList").hide();
    }
    lazyLoad.init();
}
/**
 * [SSF] MO_2022_상품리뷰코너
 * [button] 리뷰 더보기
 */
function moveReviewPage(reviewSn){
    var lastGodReviewlist = $("#"+reviewSn).children().not("[style='display: none;']").last();
    var lastShowIdx = parseInt($(lastGodReviewlist).attr("godEvlIndex"));

    //현재 선택된 탭 ID
    var godReviewTabId = $("li[godReviewTabId^='review_'][aria-selected='true']").attr("godReviewTabId");
    //현재 선택된 탭의 li 리스트
    var godReviewlist = $("#"+godReviewTabId).children("li");
    //기존에 노출된 상품리뷰 숨김
    $("#"+godReviewTabId).children().hide();
    //더보기 해당 li 노출되도록 (순환 노출)
    //ex:8개 있을 때 >> [0,1,2], [3,4,5], [6,7,0], [1,2,3]
    if(godReviewlist.length > 3){
        var i = 0;
        for(var j=0; j < reviewShowCount; j++){
            i++;
            if((lastShowIdx+i) > godReviewlist.length-1){
                lastShowIdx = 0;
                i = 0;
            }
            $("#"+godReviewTabId).children("li:eq("+(lastShowIdx+i)+")").show();
        }
        lazyLoad.init();
    }
}

// #174094 [SSF] MO_2022_브랜드 추천 START
/**
 * [SSF] MO_2022_브랜드 추천
 * [button] 다른 브랜드 보기(랜덤으로 돌림)
 * 사이클 단위로 랜덤 노출 시키기 위해 리스트 복사해서 사용
 * @returns {boolean}
 */

//1. [다른 브랜드 보기]
var brndRecoCount = 0;
function showRandomBrndReco(){
    //브랜드 추천  브랜드가 없는 경우에는 안보이도록 처리
    if(brndRecoList.length < 1){
        $(".recomSet-Brand").hide();
        return false;
    }

    //브랜드 노출 순서 셔플
    if(brndRecoShuffleList.length < 1){
        shuffleBrnd();
    }
    var currentNo = (brndRecoCount++) % brndRecoShuffleList.length;
    var brndRecoInfo = brndRecoShuffleList[currentNo];
    var nextBrndRecoInfo = brndRecoShuffleList[currentNo+1];
    if($("#brndRecoGodsList_"+brndRecoInfo.idx).children().find("li").length > 0) {
        showCurrentBrand(brndRecoInfo);
    }else{
        //현재 노출 될 브랜드 상품 조회,이미지 show
        initBrndRecoInfoProductList(brndRecoInfo, true);
    }

    if(typeof nextBrndRecoInfo != "undefined"){
        //다음에 노출 될 브랜드 상품 조회
        initBrndRecoInfoProductList(nextBrndRecoInfo, false);
    }

}
//1.2
function shuffleBrnd(){
    var brndRecoListCopy = [];
    //브랜드 리스트 복사
    if(brndRecoListCopy.length < 1){
        brndRecoListCopy = brndRecoList.slice();
        brndRecoShuffleList = [];
    }
    var len = brndRecoListCopy.length;
    for(var j=0; j < len; j++) {
        var randomIdx = Math.floor(Math.random() * brndRecoListCopy.length);
        var showBrndInfo = brndRecoListCopy.splice(randomIdx, 1);
        brndRecoShuffleList.push(showBrndInfo[0]);
    }
}

//2. 상품 리스트 조회, 이미지 처리
function initBrndRecoInfoProductList(brndRecoInfo, current){
    if(document.querySelector("#brndRecoGodsList_" + brndRecoInfo.idx + " .godList") == null){
        $.ajax({
            type: "get",
            url: "/selectBrandGodList",
            data: {brndShopId: brndRecoInfo.brndShopId, cnrSetSn: $("#recoBrndCnrSetSn").val()},
            async: true,
            beforeSend:function(){
                //	$(".page-loding").show();
            },
            complete:function(){
                //	$(".page-loding").hide();
            },
            success: function (data) {
                $("#brndRecoGodsList_"+brndRecoInfo.idx).html(data);
                if($("#brndRecoGodsList_"+brndRecoInfo.idx).children().find("li").length > 0){
                    if(current){
                        showCurrentBrand(brndRecoInfo);
                    }
                }else{
                    brndRecoList = brndRecoList.filter(function (brnd) {
                        return brnd.idx != brndRecoInfo.idx;
                    });
                    brndRecoShuffleList = brndRecoShuffleList.filter(function (brnd) {
                        return brnd.idx != brndRecoInfo.idx;
                    });
                    if(current){
                        brndRecoCount--;
                        showRandomBrndReco();
                    }
                }

            },
            error: function () {
            }
        });
    }else if(current){
        showCurrentBrand(brndRecoInfo);
    }
}

//브랜드 show
function showCurrentBrand(brndRecoInfo){
    $("[class^='brndGrp_']").hide();
    $(".brndGrp_" + brndRecoInfo.idx).show();
    $("#btnBrndReload").show();
    lazyLoad.init();
}

/**
 * [SSF] MO_2022_브랜드 추천
 * [button] 브랜드 찜하기
 * @param obj
 */
function main_clickMyBrand(obj){
    let brndId = $(obj).attr("like");
    let check = $(obj).attr("aria-pressed") == "true"? 'delete':'insert';
    main_updateMyBrnd(
        {
            check : check,
            brndId : brndId,
            noti : false
        }
    );
}

// [SSF] MO_2022_키워드(상품노출형)
function keywordGodMore(cnrDscr,cnrSetSn,_this) {
    var tabCount = $(_this).find("input[name='tabCount']").val();
    var mainPageNm = $("input[name='mainPageNm']").val();

    $.ajax({
        type : "GET",
        url : "/getTabDataMore",
        data : {"cnrDscr" : cnrDscr, "cnrSetSn" : cnrSetSn, "tabCount" : tabCount, "tmplatScKey" : mainPageNm},
        success : function (data) {
            $("#keywordGodList").empty();
            $("#keywordGodList").append(data);

            $(_this).attr("aria-selected","true");
            $(_this).siblings().attr("aria-selected","false");

            lazyLoad.init();
        }
    });
}

/**
 * [SSF] MO_2022_브랜드 추천
 * 마이브랜드 등록/삭제 처리
 * @param param
 * @param callback
 */
function main_updateMyBrnd( param ){
    if(!window.givenOption.isUser){
        commonConfirm2(window.mem_msg_require_login_confirm, function(){
            var cur = location.href;
            window.location.href = "/public/member/login?loginTarget="+encodeURIComponent(cur);
        });
        event.stopImmediatePropagation();
        return;
    }
    let { check, brndId, noti } = param;
    if( !brndId ){gnbKeyword
        return;
    }
    $.ajax({
        type : "POST",
        url : check === 'delete'?'/api/v1/display/brand/deleteMyBrand':'/api/v2/display/brand/addMyBrand',
        beforeSend : function(request){
            if( check === 'delete'){
                request.setRequestHeader("X-API-VERSION", "1.0.1");
            } else {
                request.setRequestHeader("X-API-VERSION", "2.0.0");
            }
        },
        data : {brndId : brndId},
        async : false,
        success: function (result) {
            if( !result || !result.status || result.status != 'success'){
                return;
            }

            // 호출측에 변경이 필요할때 callbak 처리
            if( check === 'delete'){
                $("[like='"+brndId+"']").attr("aria-pressed","false");
            } else {
                $("[like='"+brndId+"']").attr("aria-pressed","true");
            }

            if( noti ){
                if( check === 'delete' ){
                    notiToast("위시리스트에서\n해제되었습니다.", {type: 'favorite'});
                } else {
                    notiToast("위시리스트에\n담겼습니다.", {type: 'favorite'});
                }
            }
        }
    });
    event.stopImmediatePropagation();
}

function cnrClickLoging(tp,set,dpos,target){
    var tagPage = $("input[name='logTagPage']").val();

    action_logging({'tagPage':tagPage,'tagArea':'cornner','tp':tp,'set':set,'dpos':dpos,'target':target});
}

function setMovMuteControl() {
    $('.movTxtBanner').each(function(){
        var $this = $(this);

        if ($this.find('.type-video').length) {
            if ($this.hasClass('initialized')) return;

            $this.on('click', '.type-video .mute', function(){
                var video = $(this).parent().find('video');
                video.get(0).muted = !video.get(0).muted;
                if (video.get(0).muted) {
                    $(this).attr('aria-pressed', true)
                }
                else {
                    $(this).attr('aria-pressed', false);
                    video.get(0).volume = 0.2;
                }
            });

            $this.addClass('initialized');
        }

    });
}

$(document).ready(function(){
  $('li[class="lazy-tab"]').click(function(e){
    var me = $("#"+$(this).attr("img-con-id"));
    me.find('img[class="lazy-img"]').each(function () {
      var that = $(this);
      if( that.attr("original-img") ){
        that.attr("src", that.attr("original-img"));
        that.removeAttr("original-img");
      }
    });
  });

    // [SSF]  MO_2022_브랜드 룩북
    var lookbookSetSimpleOption = {
        slidesPerView: 'auto',
        spaceBetween: 15,
        loop:false,
        pagination: {
            el: ".swiper-pagination",
            type: "progressbar"
        },
        on: {
            init: function(swiper){
                lazyLoadSwiper.init(this);
            }
        },
        watchSlidesProgress:true
    }

    $(".lookbookSet .swiper").each(function(idx){
        lookbookSet[idx] = new Swiper(this, lookbookSetSimpleOption);

        $("*[role='listbox']", $(this).parent()).on('click', 'li', function(e){
            // ajax data 호출 시,
            var el = $(this).parents('section').find('.swiper');

            var cnrSetSn = $(this).find("input[name='cnrSetSn']").val();
            //var cnrDscr = $("section[name='brandLookbook']").find("input[name='cnrDscr']").val();
            var cnrDscr = $(this).find("input[name='dspCnrDscr']").val();
            var tabCount = parseInt($(this).find("input[name='tabCount']").val()) + 1;
            var _this = $(this);
            var mainPageNm = $("input[name='mainPageNm']").val();

            $.ajax({
                type : "GET",
                url : "/getTabDataMore",
                data : {"cnrSetSn" : cnrSetSn, "cnrDscr" : cnrDscr, "tabCount" : tabCount, "tmplatScKey" : mainPageNm},
                success : function (data) {
                    lookbookSet[idx].destroy(); //data insert 전, slide 초기화
                    $("#lookbookList"+cnrDscr).empty();
                    $("#lookbookList"+cnrDscr).html(data);

                    $(_this).attr("aria-selected","true");
                    $(_this).siblings().attr("aria-selected","false");

                    lookbookSet[idx] = new Swiper(el[0], lookbookSetSimpleOption); // data insert 후, slide 호출
                }
            });

        });
    });

    // [SSF] MO_2022_혜택 띠배너
    $(".sashBanner .swiper").each(function(idx){
        var $this = $(this);
        var slide_len = $(".swiper-slide", this).length;
        var loop, pagination;

        if(slide_len > 1){
            loop = true;
            $(".swiper-slide span", this).css('opacity', 0);
            pagination = {
                el: ".swiper-pagination",
                type: "fraction"
            }
        }else{
            loop = false;
            pagination = false;
        }

        sashBanner[idx] = new Swiper(this, {
            //effect:'fade',
            loop:loop,
            autoplay:{
                delay: 3500,
                disableOnInteraction:false
            },pagination: pagination,
            on:{
                'init':function(swiper){
                    $this.on('click', 'button.autoplay', function(){
                        if($(this).attr('aria-label') == "stop"){
                            $(this).attr('aria-label', 'play');
                            swiper.autoplay.stop();
                        }else{
                            $(this).attr('aria-label', 'stop');
                            swiper.autoplay.start();
                        }
                    });

                    lazyLoadSwiper.init(this);
                }
            }
        })
    });

    // [SSF] MO_2022_BRAND(BI LOGO)
    var hotBrands = new Swiper(".hotBrands .swiper", {
        loop: false,
        pagination : {
            el: ".swiper-pagination",
            type: "progressbar"
        },
        on: {
            init:function(swiper){
                lazyLoadSwiper.init(this);
            }
        }
    });

    // [SSF] MO_2022_상품리스트(더보기) 01-04
    var categoryBrandSetOption = {
        slidesPerView: 'auto',
        spaceBetween: 15,
        loop:false,
        pagination: {
            el: ".swiper-pagination",
            type: "progressbar"
        },
        on: {
            init: function(swiper){
                lazyLoadSwiper.init(this);
            }
        },
        watchSlidesProgress:true
    }

    $(".categorySet-brand .swiper").each(function(idx){
        categoryBrandSet[idx] = new Swiper(this, categoryBrandSetOption);
    });

    // [SSF] MO_2022_상품리스트(세로형)
    if($('section[id*=godListD_]').length > 0){
        $('section[id*=godListD_]').each(function(index,item){
            var tabIdx = $(item).find("li[aria-selected='true']").index();
            
            if(tabIdx != -1){
                var godList = $(item).find(".godList").eq(tabIdx);
                godList.siblings("ul").css("display","none");
            }
        });
    }

    $('[id*=godListD_] .tabC li').on("click",function (){
        var index = $(this).index();
        var godList = $(this).parents().siblings('ul').eq(index);
        
        $(this).attr("aria-selected","true");
        $(this).siblings().attr("aria-selected","false");

        godList.css("display","");
        godList.siblings("ul").css("display","none");
    });


    var promotionSetSlide = [];

    //ajax 로 호출할 경우, 카드 정렬을 위해  cardMasonry_align() 호출
    $(".picksSet-List .swiper").each(function(idx){
        var loop, pagination;
        if($(".swiper-slide", this).length > 1){
            loop=true;
            pagination ={
                el: ".swiper-pagination",
                type: "progressbar"
            }
        }else{
            loop=false;
            pagination=false
        }
        promotionSetSlide[idx] = new Swiper(this, {
            slidesPerView: 1,
            spaceBetween: 0,
            loop:false,
            pagination:pagination,
            on: {
                transitionStart: function(swiper){
                    try{
                        allVideos_Pause($(swiper.$el[0]));
                    }catch (ignore){
                        console.log(ignore);
                    }
                },transitionEnd: function(swiper){
                    try{
                        //videoinSlide_Play($(swiper.$el[0]));
                        videoinSlide_Play($(swiper.slides[swiper.activeIndex])); /*//220830*/
                    } catch (ignore){
                        console.log(ignore);
                    }
                },init: function(swiper){
                    lazyLoadSwiper.init(this);
                }
            },
            preloadImages: true
        });
    });

    if($(".picksSet-Full .swiper .swiper-slide").length > 0 ) {
        var promotionSetFull = new Swiper(".picksSet-Full .swiper", {
            slidesPerView: "auto",
            centeredSlides: false,
            spaceBetween: 15,
            loop:false,
            pagination: {
                el: ".swiper-pagination",
                type: "progressbar"
            }/*동영상 미노출로 변경됨,on: {
                transitionStart: function(swiper){
                    allVideos_Pause($(swiper.$el[0]));
                },transitionEnd: function(swiper){
                    videoinSlide_Play($(swiper.$el[0]))
                }
            }*/
            ,
            preloadImages: true,
            on: {
                init: function(swiper){
                    lazyLoadSwiper.init(this);
                }
            }
        });
    }

    /*[SSF] MO_2022_동영상 배너*/
    setMovMuteControl();

    if($('#newInNEW_CTGType').length > 0){
        setTimeout(function(){
            setNewinSwiper();
        },400);

        // 함수명 임의 지정
        function setNewinSwiper(){
            let $this = $('#newInNEW_CTGType');
            if (!$this.length) return;
            let tab = $this.find('.tabC[role=listbox]');
            let tabs = tab.find('[role=option]');
            let wrapper = $this.find('.swiper-wrapper');
            let length = tabs.length;
            let slides = (function() {
                for (let i = 0; i < length; i++) {
                    if(tabs[i].getAttribute('ctgryNo') == null || tabs[i].getAttribute('ctgryNo') == ""){
                        wrapper.append(`<div class="swiper-slide" data-ctgryno="All"></div>`);
                    }else{
                        wrapper.append(`<div class="swiper-slide" data-ctgryno="${tabs[i].getAttribute('ctgryNo')}"></div>`);
                    }
                }

                return wrapper.find('.swiper-slide');
            })();

            var swiper = new Swiper($this[0].querySelector('.swiper'), {
                slidesPerView: 1,
                autoHeight: true, // #211705:20230728
                // loop: true, //순환 하지 않는 것으로 협의됨.
                on: {
                    init: function (){
                        lazyLoadSwiper.init(this);
                    },
                    transitionEnd: function(swiper) {
                        let nextIndex = swiper.activeIndex + 1;
                        try {
                            exe_listOption(tab, tabs[swiper.activeIndex]);
                            getLoadSlides(swiper.activeIndex);
                        }
                        catch(e) {}
                    }
                }
            });

            getLoadSlides(0);

            // 탭 클릭 시 처리
            tabs.on('click', function(e){
                e.preventDefault();

                let index = $(this).index();

                getLoadSlides(index);
                swiper.slideTo(index, 0);
});

            function getLoadSlides(index) {

                // 현재
                setting(index);

                // 이전
                setting(index - 1);

                // 다음
                setting(index + 1);

                function setting(i) {
                    try {
                        if (slides[i] && !slides[i].classList.contains('done')) {
                            getCateItemList(i);
                            slides[i].classList.add('done');
                        }
                    }
                    catch(e) {}
                }
            }

            function getCateItemList(index) {

                // ctgryNo 사용 가능
                let ctgryNo = tabs[index].getAttribute('ctgryNo');
                let ctgryNm = tabs[index].innerText;

                $.ajax({
                    type: "GET",
                    url: "/newInCorner",
                    data: {
                        "brndId": givenMain.brndId,
                        "dspCtgryNo": ctgryNo,
                        "imgTurn": $("#newInNEW_CTGType").attr("imgTurn"),
                        isFilter: true,
                        "line": 5,
                        "ctgrySectCd": givenMain.dspCtgryInfoDspCtgryCtgrySectCd,
                        "dscr": "newInNEW_CTGType"
                    },
                    async: true,
                    success: function (data) {
                            var html = "";
                            html += "<ul class='godList column3' id='newin-godList"+ctgryNo+"'>"
                            html += "<input type='hidden' name='newinLoadYn' value='Y' />"
                            html += "<h4 class='wa_hidden'>"+ givenMain.mallmain_corner_newIn_title_all +"</h4>";
                            html += data;
                            html += "</ul>";

                            if(ctgryNo == null || ctgryNo == ""){
                                wrapper.find(`[data-ctgryno=All]`).append(html);
                            }else {
                                wrapper.find(`[data-ctgryno=${ctgryNo}]`).append(html);
                            }
                            lazyLoadSwiper.init(swiper);

                            // #211705:20230728
                            swiper.updateAutoHeight(0);
                    },
                    error : function(jqXHR, textStatus, errorThrown) {
                        console.log(textStatus)
                    }
                });
            }
        }
    }

    if($('#rankingCLICK_RANK_CTGType').length > 0){
        setTimeout(function() {
            if($("#dspCtgryListCLICK_RANK_CTGType").val() != '' && $("#dspCtgryListCLICK_RANK_CTGType").val() != null) { //bo에 세팅된 카테고리 있어야만 스와이프 실행
                setClickRankSwiper();
            }
        }, 500);

        function setClickRankSwiper(){
            let $this = $('#rankingCLICK_RANK_CTGType');
            if (!$this.length) return;
            let tab = $this.find('.tabC[role=listbox]');
            let tabs = tab.find('[role=option]');
            let wrapper = $this.find('.swiper-wrapper');
            let length = tabs.length;
            let slides = (function() {
                for (let i = 0; i < length; i++) {
                    if(tabs[i].getAttribute('ctgryNo') == null || tabs[i].getAttribute('ctgryNo') == ""){
                        wrapper.append(`<div class="swiper-slide" data-ctgryno="All"></div>`);
                    }else{
                        wrapper.append(`<div class="swiper-slide" data-ctgryno="${tabs[i].getAttribute('ctgryNo')}"></div>`);
                    }
                }

                return wrapper.find('.swiper-slide');
            })();
            var swiper = new Swiper($this[0].querySelector('.swiper'), {
                slidesPerView: 1,
                autoHeight: true, // #211705:20230728
                // loop: true, //순환 하지 않는 것으로 협의됨.
                on: {
                    init: function (){
                        lazyLoadSwiper.init(this);
                    },
                    transitionEnd: function(swiper) {
                        let nextIndex = swiper.activeIndex + 1;
                        try {
                            exe_listOption(tab, tabs[swiper.activeIndex]);
                            getLoadSlides(swiper.activeIndex);
                        }
                        catch(e) {}
                    }
                }
            });

            //getLoadSlides(0);

            // 탭 클릭 시 처리
            tabs.on('click', function(e){
                e.preventDefault();

                let index = $(this).index();

                getLoadSlides(index);
                swiper.slideTo(index, 0);
            });
            setMainObserver("rankingCLICK_RANK_CTGType");
            function getLoadSlides(index) {

                // 현재
                setting(index);

                // 이전
                setting(index - 1);

                // 다음
                setting(index + 1);

                function setting(i) {
                    try {
                        if (slides[i] && !slides[i].classList.contains('done')) {
                            getCateItemList(i);
                            slides[i].classList.add('done');
                        }
                    }
                    catch(e) {}
                }
                if(index == 0 ){
                    $("#ranking-navCLICK_RANK_CTGType > li").eq(0).attr("aria-selected","true").siblings().attr("aria-selected","false");
                }

            }

            function getCateItemList(index) {

                let ctgryNo = tabs[index].getAttribute('ctgryNo');
                let cnrType = "javascript:rankingViewMore('CLICK_RANK_CTGType')";
                $.ajax({
                    type: "GET",
                    url: "/rankingCorner",
                    data: getRankingParam("CLICK_RANK_CTGType", ctgryNo),
                    async: true,
                    success: function (data) {
                        var html = "<ul class='godList column3' id='ranking-godList" + ctgryNo + "'>";
                        html += data;
                        html += "</ul>";
                        html += "<a href='javascript:void(0);' class='go' onClick="+cnrType+">랭킹 바로가기</a>";
                        if(ctgryNo == null || ctgryNo == ""){
                            wrapper.find(`[data-ctgryno=All]`).append(html);
                        }else {
                            wrapper.find(`[data-ctgryno=${ctgryNo}]`).append(html);
                        }

                        lazyLoadSwiper.init(swiper);

                        // #211705:20230728
                        swiper.updateAutoHeight(0);
                    },
                    error : function(jqXHR, textStatus, errorThrown) {
                        console.log(textStatus)
                    }
                });
            }
        }
    }

    if($('#rankingSALE_RANK_CTGType').length > 0){
        setTimeout(function() {
            if($("#dspCtgryListSALE_RANK_CTGType").val() != '' && $("#dspCtgryListSALE_RANK_CTGType").val() != null) { //bo에 세팅된 카테고리 있어야만 스와이프 실행
                setSaleRankSwiper();
            }
        }, 500);

        function setSaleRankSwiper(){
            let $this = $('#rankingSALE_RANK_CTGType');
            if (!$this.length) return;
            let tab = $this.find('.tabC[role=listbox]');
            let tabs = tab.find('[role=option]');
            let wrapper = $this.find('.swiper-wrapper');
            let length = tabs.length;
            let slides = (function() {
                for (let i = 0; i < length; i++) {
                    if(tabs[i].getAttribute('ctgryNo') == null || tabs[i].getAttribute('ctgryNo') == ""){
                        wrapper.append(`<div class="swiper-slide" data-ctgryno="All"></div>`);
                    }else{
                        wrapper.append(`<div class="swiper-slide" data-ctgryno="${tabs[i].getAttribute('ctgryNo')}"></div>`);
                    }
                }

                return wrapper.find('.swiper-slide');
            })();

            var swiper = new Swiper($this[0].querySelector('.swiper'), {
                slidesPerView: 1,
                autoHeight: true, // #211705:20230728
                // loop: true, //순환 하지 않는 것으로 협의됨.
                on: {
                    init: function (){
                        lazyLoadSwiper.init(this);
                    },
                    transitionEnd: function(swiper) {
                        let nextIndex = swiper.activeIndex + 1;
                        try {
                            exe_listOption(tab, tabs[swiper.activeIndex]);
                            getLoadSlides(swiper.activeIndex);
                        }
                        catch(e) {}
                    }
                }
            });

            //getLoadSlides(0);

            // 탭 클릭 시 처리
            tabs.on('click', function(e){
                e.preventDefault();

                let index = $(this).index();

                getLoadSlides(index);
                swiper.slideTo(index, 0);
            });

            setMainObserver("rankingSALE_RANK_CTGType");

            function getLoadSlides(index) {

                // 현재
                setting(index);

                // 이전
                setting(index - 1);

                // 다음
                setting(index + 1);

                function setting(i) {
                    try {
                        if (slides[i] && !slides[i].classList.contains('done')) {
                            getCateItemList(i);
                            slides[i].classList.add('done');
                        }
                    }
                    catch(e) {}
                }
                if(index == 0 ){
                    $("#ranking-navSALE_RANK_CTGType > li").eq(0).attr("aria-selected","true").siblings().attr("aria-selected","false");
                }

            }

            function getCateItemList(index) {

                let ctgryNo = tabs[index].getAttribute('ctgryNo');
                let cnrType = "javascript:rankingViewMore('SALE_RANK_CTGType')";
                $.ajax({
                    type: "GET",
                    url: "/rankingCorner",
                    data: getRankingParam("SALE_RANK_CTGType", ctgryNo),
                    async: true,
                    success: function (data) {
                        var html = "<ul class='godList column3' id='ranking-godList" + ctgryNo + "'>";
                        html += data;
                        html += "</ul>";
                        html += "<a href='javascript:void(0);' class='go' onClick="+cnrType+">랭킹 바로가기</a>";
                        if(ctgryNo == null || ctgryNo == ""){
                            wrapper.find(`[data-ctgryno=All]`).append(html);
                        }else {
                            wrapper.find(`[data-ctgryno=${ctgryNo}]`).append(html);
                        }

                        lazyLoadSwiper.init(swiper);

                        // #211705:20230728
                        swiper.updateAutoHeight(0);
                    },
                    error : function(jqXHR, textStatus, errorThrown) {
                        console.log(textStatus)
                    }

                });
            }
        }
    }

    if($('#sectionBestReview').length > 0){
        setBestReviewSwiper();
    }

    function setBestReviewSwiper(){
        let $this = $('#sectionBestReview');
        if (!$this.length) return;
        let tab = $this.find('.tabC[role=listbox]');
        let tabs = tab.find('[role=option]');
        let wrapper = $this.find('.swiper-wrapper');
        var swiper = new Swiper($this[0].querySelector('.swiper'), {
            slidesPerView: 1,
            //autoHeight: true, // #211705:20230728
            // loop: true, //순환 하지 않는 것으로 협의됨.
            on: {
                init: function (){
                    lazyLoadSwiper.init(this);
                },
                transitionEnd: function(swiper) {
                    let nextIndex = swiper.activeIndex + 1;
                    try {
                        exe_listOption(tab, tabs[swiper.activeIndex]);
                    }
                    catch(e) {}
                }
            }
        });

        // 탭 클릭 시 처리
        tabs.on('click', function(e){
            e.preventDefault();
            let index = $(this).index();
            swiper.slideTo(index, 0);
        });
    }

    if($('#8S_RANK').length > 0){
        setRankingSwiper();
        function setRankingSwiper() {
            var type ="8S_RANK";
            let $8sSection = $("#" + type);
            let $8sNav = $("#best-ranking-nav");
            let $this = $('#8S_RANK');
            if (!$this.length) return;
            let tab = $this.find('.tabC[role=listbox]');
            let tabs = tab.find('[role=option]');
            let wrapper = $this.find('.swiper-wrapper');
            let length = tabs.length;
            let slides = (function () {
                for (let i = 0; i < length; i++) {
                    if(tabs[i].getAttribute('ctgryNo') == null || tabs[i].getAttribute('ctgryNo') == ""){
                        wrapper.append(`<div class="swiper-slide" data-ctgryno="All"></div>`);
                    }else{
                        wrapper.append(`<div class="swiper-slide" data-ctgryno="${tabs[i].getAttribute('ctgryNo')}"></div>`);
                    }
                }

                return wrapper.find('.swiper-slide');
            })();

            var swiper = new Swiper($this[0].querySelector('.swiper'), {
                slidesPerView: 1,
                autoHeight: true, // #211705:20230728
                // loop: true, //순환 하지 않는 것으로 협의됨.
                on: {
                    init: function () {
                        lazyLoadSwiper.init(this);
                    },
                    transitionEnd: function (swiper) {
                        let nextIndex = swiper.activeIndex + 1;
                        try {
                            exe_listOption(tab, tabs[swiper.activeIndex]);
                            getLoadSlides(swiper.activeIndex);
                        } catch (e) {
                        }
                    }
                }
            });

            getLoadSlides(0);

            // 탭 클릭 시 처리
            tabs.on('click', function (e) {
                e.preventDefault();

                let index = $(this).index();

                getLoadSlides(index);
                swiper.slideTo(index, 0);
            });

            function getLoadSlides(index) {

                // 현재
                setting(index);

                // 이전
                setting(index - 1);

                // 다음
                setting(index + 1);

                function setting(i) {
                    try {
                        if (slides[i] && !slides[i].classList.contains('done')) {
                            getCateItemList(i);
                            slides[i].classList.add('done');
                        }
                    } catch (e) {
                    }
                }
            }

            function getCateItemList(index) {

                // ctgryNo 사용 가능
                let ctgryNo = null;
                ctgryNo = tabs[index].getAttribute('ctgryNo');
                let ctgryNm = tabs[index].innerText;
                let ctgryFlterCd = tabs[index].getAttribute('ctgryFlterCd');
                $.ajax({
                    type: "GET",
                    url: "/rankingCorner",
                    data: getRankingParam(type, ctgryNo),
                    async: true,
                    success: function (data) {
                        var html = data;
                        if (ctgryNo == "" || ctgryNo == null) {
                            wrapper.find(`[data-ctgryno=All]`).append(html);
                        } else {
                            wrapper.find('[data-ctgryno='+ctgryNo+']').append(html);
                        }

                        let showCtryNm = $("#" + type).find("input[name='showCtryNm']").val();

                        if (showCtryNm != null && showCtryNm != "") {
                            if (showCtryNm.indexOf("CTGRY_ALL") < 0) {
                                $8sSection.remove();
                            } else {
                                $8sSection.find("#best-ranking-nav > li").each(function () {
                                    if (showCtryNm.indexOf($(this).attr("ctgryFlterCd")) == -1) {
                                        $(this).remove();
                                        wrapper.find('[data-ctgryno='+$(this).attr("ctgryNo")+']').remove();
                                    }
                                });

                                let params = getUrlParams();
                                if (!params.ctgryFlterCd) {
                                    $($($8sNav.find('li')).get(randomArrayIndex($8sNav.find('li').length))).trigger('click');
                                } else {
                                    let ctgryFlterCd = params.ctgryFlterCd || "CTGRY_ALL";
                                    $8sNav.find("li[ctgryfltercd='" + ctgryFlterCd + "']").trigger("click");
                                }
                            }
                        }
                        lazyLoadSwiper.init(swiper);

                        // #211705:20230728
                        swiper.updateAutoHeight(0);
                        set_scrollX_po();
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                    }
                });
            }
        }
    }

    if ($(".main-tabs li.selected").length < 1) {
        $(".main-tabs li").eq(0).addClass("selected");
    }

    /*[SSF] MO_2022_상품리뷰코너*/
    try{
        $("#sectionBestReview").show();
        //로드시 0번 탭 선택
        $($("li[godReviewTabId^='review_']")[0]).children("a").click();
        //#179375 카테고리 영역 스크롤
        set_scrollX_po();
    }catch (e) {
        console.log(e);
    }
    // #174094[SSF] MO_2022_상품리뷰코너 관련 js END

    /*[SSF] MO_2022_브랜드*/
    try{
        // 브랜드 추천 상품리스트 조회 (화면 로드시 모든 브랜드 상품 조회)
        if(typeof(brndRecoList) != "undefined"){
            // initBrndRecoProductList(brndRecoList);
            showRandomBrndReco();
        }
    }catch (e) {
        console.log("brndReco error::", e);
    }
    //<!-- #174094 [SSF] MO_2022_브랜드 추천 END -->

    /* MO_2024_카테고리 바로가기2 */
    if($('#sectionGotoCategorySlide').length > 0){
        ssf.main.contents.swiper("gotoCategorySlide");
    }



});

//#210579 lazyload 호출 처리(다이버스타일, 클릭랭킹 코너)
function setMainObserver(type){
    const mainObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                if(entry.target.id == "rankingCLICK_RANK_CTGType"){
                    //클릭랭킹
                    if ($("#rankingCLICK_RANK_CTGType").length > 0 ) {
                        $("#ranking-navCLICK_RANK_CTGType > li").eq(0).click();
                    }
                }else if(entry.target.id == "rankingSALE_RANK_CTGType"){
                    //판매랭킹
                    if ($("#rankingSALE_RANK_CTGType").length > 0 ) {
                        $("#ranking-navSALE_RANK_CTGType > li").eq(0).click();
                    }
                }else if(entry.target.id == "diverDIVER_STYLE_BANNER"){
                    //다이버스타일
                    if ($("#diverDIVER_STYLE_BANNER").length > 0) {
                        var tabInit = 0;
                        var tabCheck = "N";
                        if(tabCheck == 'N' && $("#diverDIVER_STYLE_BANNER .tabB li").length > 0){
                            tabInit=Math.floor(Math.random() * $("#diverDIVER_STYLE_BANNER .tabB li").length);
                            $("#diverDIVER_STYLE_BANNER .tabB li").eq(tabInit).click();
                        }
                    }
                }
                observer.unobserve(entry.target);
            }
        });
    }, {rootMargin: '150% 0px 0px 0px', threshold: [0, 0.3, 1]});

    if(type == "rankingCLICK_RANK_CTGType"){
        mainObserver.observe(document.getElementById("rankingCLICK_RANK_CTGType"));
    }else if(type == "rankingSALE_RANK_CTGType"){
        mainObserver.observe(document.getElementById("rankingSALE_RANK_CTGType"));
    }else if(type == "diverDIVER_STYLE_BANNER"){
        mainObserver.observe(document.getElementById("diverDIVER_STYLE_BANNER"));
    }
}

    var keywordTag = [];
    var categoryHorizontal = [];
    var ssfShopNowImages = [];
    var ssfShopNowCaptions = [];

    $(document).ready(function(){

        /* [SSF] MO_2022_대배너 */
        /* [SSF] MO_2022_브랜드 대배너 */
        /* //#226243: 다수 swiper 적용을 위한 스크립트 수정 */
        ssf.main.corner.init();

        var keywordTagFullOption = {
            slidesPerView: 'auto',
            spaceBetween: 15,
            loop:false,
            pagination: {
                el: ".swiper-pagination",
                type: "progressbar"
            },
            watchSlidesProgress:true,
            preloadImages: true,
            /*lazy: {
                loadPrevNext: true,
                loadPrevNextAmount: 3,
            }*/
            on: {
                init:function(swiper){
                    lazyLoadSwiper.init(this);
                }
            }
        }

        $(".keywordTag .swiper").each(function(idx){
            var $wrap =  $(this).parent();

            keywordTag[idx] = new Swiper(this, keywordTagFullOption);

            $("*[role='listbox']", $(this).parent()).on('click', 'li', function(e){
                // ajax data 호출 시,
                var el = $(this).parents('section').find('.swiper');
                keywordTag[idx].destroy(); //data insert 전, slide 초기화
                keywordTag[idx] = new Swiper(el[0], keywordTagFullOption); // data insert 후, slide 호출
            });
        });
        // [SSF] MO_2022_키워드(상품노출형)

        // [SSF] MO_2022_탭 상품 리스트(가로형)
        var categoryHorizontalOption = {
            slidesPerView: 'auto',
            spaceBetween: 15,
            loop:false,
            pagination: {
                el: ".swiper-pagination",
                type: "progressbar"
            },
            watchSlidesProgress:true
            ,on: {
                init:function(swiper){
                    lazyLoadSwiper.init(this);
                }
            }
        }

        $(".categorySet-Horizontal .swiper").each(function(idx){
            categoryHorizontal[idx] = new Swiper(this, categoryHorizontalOption);

            $("*[role='listbox']", $(this).parent()).on('click', '> *', function(e){
                // ajax data 호출 시,
                var el = $(this).parents('section').find('.swiper');
                categoryHorizontal[idx].destroy(); //data insert 전, slide 초기화
                categoryHorizontal[idx] = new Swiper(el[$(this).index()], categoryHorizontalOption); // data insert 후, slide 호출

                el.hide();
                el.eq($(this).index()).show();
            });
        });
        // [SSF] MO_2022_탭 상품 리스트(가로형)

        /* 프로모션/이벤트 코너 */
        $(".ssfShopNow .swiper.captions").each(function(idx){
            var $this = $(this);
            var slide_len = $(".swiper-slide", this).length;

            if(slide_len > 1){
                loop = true;
            }else{
                loop = false;
            }

            ssfShopNowCaptions[idx] = new Swiper(this, {
                effect:'fade',
                loop:loop,
                // #173386:20220929
                on: {
                    init:function(swiper){
                        lazyLoadSwiper.init(this);
                    }
                }
            });
        });


        $(".ssfShopNow .swiper.imgs").each(function(idx){
            var $this = $(this);
            var slide_len = $(".swiper-slide", this).length;
            var loop, pagination;

            if(slide_len > 1){
                loop = true;
                pagination = {
                    el: ".swiper-pagination",
                    type: "fraction"
                }
            }else{
                loop = false;
                pagination = false;
                $(".controls", this).hide();
            }

            var controls_align = function(){
                $(".ssfShopNow .controls").css({
                    top: ($(".ssfShopNow .swiper-slide .img").height() - $(".ssfShopNow .controls").outerHeight() - 6)+"px"
                });
            }

            ssfShopNowImages[idx] = new Swiper(this, {
                loop:loop,
                autoplay:{
                    delay: 3500,
                    disableOnInteraction:false/*//20220826*/
                },
                pagination: pagination,
                on:{
                    'init':function(swiper){

                        // #173386:20220929
                        lazyLoadSwiper.init(this);

                        $this.on('click', 'button.autoplay', function(){
                            if($(this).attr('aria-label') == "stop"){
                                $(this).attr('aria-label', 'play');
                                swiper.autoplay.stop();
                            }else{
                                $(this).attr('aria-label', 'stop');
                                swiper.autoplay.start();
                            }
                        });

                        controls_align();

                        $(window).on('resize', function(){
                            controls_align();
                        })
                    },/*, 동영상 미노출로 변경됨
                                transitionStart: function(swiper){
                                    allVideos_Pause($(swiper.$el[0]));
                                },transitionEnd: function(swiper){
                                    videoinSlide_Play($(swiper.$el[0]))
                                }*/
                    transitionEnd: function(swiper){
                        try{
                            ssfShopNowCaptions[idx].slideTo(swiper.activeIndex);
                        } catch (ignore){
                            console.log(ignore);
                        }
                    }
                }
            });
            /* #187038 [MO] 이벤트 모듈 노출 텍스트와 이미지 따로 움직이는 현상 */
            ssfShopNowImages[idx].controller.control = ssfShopNowCaptions[idx]; 
            ssfShopNowCaptions[idx].controller.control = ssfShopNowImages[idx];
        });
        /* //프로모션/이벤트 코너 */

    });


/*#225817 [SSF] MO_2024_타임딜_01, [SSF] MO_2024_위클리 핫딜_01 타이머 세팅*/
var arrData = [];
try{
    if(typeof($("#timeDealDspEndDt").val()) != "undefined"){
        arrData.push("timeDealDspEndDt");
    }
    if(typeof($("#weeklyDealDspEndDt").val()) != "undefined"){
        arrData.push("weeklyDealDspEndDt");

        var todayDate = new Date();
        var a1 = $("#weeklyDealDspEndDt").val();
        var a2 = $("#weeklyDealDspBegDt").val();
        var dealDspEndDt = new Date(parseInt(a1.substring(0,4)),
                                    parseInt(a1.substring(4,6)) - 1,
                                    parseInt(a1.substring(6,8)),
                                    parseInt(a1.substring(8,10)),
                                    parseInt(a1.substring(10,12)),
                                    parseInt(a1.substring(12,14)));
        var dealDspBegDt = new Date(parseInt(a2.substring(0,4)),
                                    parseInt(a2.substring(4,6)) - 1,
                                    parseInt(a2.substring(6,8)),
                                    parseInt(a2.substring(8,10)),
                                    parseInt(a2.substring(10,12)),
                                    parseInt(a2.substring(12,14)));

        var timeGauge = (todayDate - dealDspBegDt) / (dealDspEndDt - dealDspBegDt);
        $("#weeklyGauge").attr("style", "width:" + timeGauge*100 + "%");
    }

} catch(err) {}

if(arrData.length > 0){
    $.ajax({
       type : 'GET',
       url : '/special/hd/serverTime.json',
       cache: false,
       complete : function(req) {
           try{
               var dateString = req.getResponseHeader('Date');
               var serverTimestamp = new Date(dateString).getTime();

               var objEndDate = {}
               arrData.forEach(function(item){
                   objEndDate['endDate'+item] = new Date(parseInt($("#"+item).val().substring(0, 4), 10),
                       parseInt($("#"+item).val().substring(4, 6), 10) - 1,
                       parseInt($("#"+item).val().substring(6, 8), 10),
                       parseInt($("#"+item).val().substring(8, 10), 10),
                       parseInt($("#"+item).val().substring(10, 12), 10),
                       parseInt($("#"+item).val().substring(12, 14), 10)
                   );
                   var closeTimestamp =  objEndDate['endDate'+item].getTime();
                   timerObj['timer'+item].init(serverTimestamp, closeTimestamp);
               });

           } catch(err) {}
       },
       error: function(pa_data, status, err) {
       }
    });

    var timerObj = {}
    try{
        arrData.forEach(function(item){
            timerObj['timer'+item] = {
               timerInterval : null
               , closeTimestamp : null
               , serverTimestamp : ''
               , init : function(serverTimestamp, closeTimestamp){
                    timerObj['timer'+item].serverTimestamp = serverTimestamp;
                    timerObj['timer'+item].closeTimestamp = closeTimestamp;
                    timerObj['timer'+item].startTimer();
               }
               , startTimer : function(){
                    timerObj['timer'+item].stopTimer();
                    timerObj['timer'+item].setTimer();
                    timerObj['timer'+item].timerInterval = setInterval(timerObj['timer'+item].setTimer,1000);
               }
               , stopTimer : function(){
                   if(timerObj['timer'+item].timerInterval != null){
                       clearInterval(timerObj['timer'+item].timerInterval);
                   }
               }
               , setTimer : function(){
                   var temp = 0;
                   var days = 0;
                   var timedealHours = 0;
                   var hours = 0;
                   var minutes = 0;
                   var seconds = 0;
                   if(timerObj['timer'+item].closeTimestamp <= timerObj['timer'+item].serverTimestamp){
                       timerObj['timer'+item].stopTimer();
                   } else {
                       temp = Math.abs(timerObj['timer'+item].closeTimestamp - timerObj['timer'+item].serverTimestamp) / 1000;
                       days = Math.floor(temp / 86400);
                       timedealHours = Math.floor(temp / 3600);
                       temp -= days * 86400;
                       hours = Math.floor(temp / 3600) % 24;
                       temp -= hours * 3600;
                       minutes = Math.floor(temp / 60) % 60;
                       temp -= minutes * 60;
                       seconds = temp % 60;
                   }
                    if(days > 99){
                       days = 99;
                       hours = 23;
                       minutes = 59;
                       seconds = 59;
                   }

                    if(item == 'timeDealDspEndDt' && timedealHours > 99){
                        timedealHours = 99;
                        hours = 23;
                        minutes = 59;
                        seconds = 59;
                    }

                    if(item == 'timeDealDspEndDt'){
                        if(timedealHours < 100){
                            $("#timeDealDTime").text(('0' + timedealHours).slice(-2)+':'+('0' + minutes).slice(-2)+':'+('0' + seconds).slice(-2));
                        }

                    } else if (item == 'weeklyDealDspEndDt'){
                        if( days < 1){
                            $("#weeklyDealDTime").text(('0' + hours).slice(-2)+':'+('0' + minutes).slice(-2)+':'+('0' + seconds).slice(-2));
                        }else{
                            $("#weeklyDealDTime").text( days + '일 ' + ('0' + hours).slice(-2)+':'+('0' + minutes).slice(-2)+':'+('0' + seconds).slice(-2));
                        }
                    }

                    timerObj['timer'+item].serverTimestamp += 1000;
               }
            }
        })

    } catch(err) {}
}

$('[id*=tabGod_TAB_GOD] .tabC li').on("click",function (){
    var cnrSetSn = $(this).find("input[name='cnrSetSn']").val();
    var cnrSn = $(this).parents(".categorySet-Horizontal").find("input[name='cnrSn']").val();
    var mainPageNm = $("input[name='mainPageNm']").val();

    $.ajax({
        type : "GET",
        url : "/getTabDataMore",
        data : {"cnrSetSn" : cnrSetSn, "tmplatScKey" : mainPageNm},
        success : function (data) {
            $("#tabGod_TAB_GOD_"+cnrSn+" ."+cnrSn).remove();
            $("#tabGod_TAB_GOD_"+cnrSn+" .more-view").remove();
            $("#tabGod_TAB_GOD_"+cnrSn).append(data);
        
            lazyLoad.init();
        }
    });
});
var console = window.console || {
	log : function() {
	}
};
(function() {
//	$.browser.msie = false;
//	$.browser.version = 0;
//	if (navigator.userAgent.match(/MSIE ([0-9]+)\./)) {
//		$.browser.msie = true;
//		$.browser.version = RegExp.$1;
//	}
//	$(document).on("contextmenu", function(event) {
//		return false;
//	}); //우클릭방지
//	$(document).on("selectstart", function(event) {
//		return false;
//	}); //더블클릭을 통한 선택방지
//	$(document).on("dragstart", function(event) {
//		return false;
//	}); //드래그
})();
if (typeof String.prototype.trim !== 'function') {
	String.prototype.trim = function() {
		return this.replace(/\s\s*$/, '');
	}
}
function addCommas(num) {
	if (num == null || num == undefined || num == '') {
		return 0;
	} else {
		return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}
}
function noCommas(num) {
	if (num == null || num == undefined || num == '') {
		return 0;
	} else {
		return num.toString().replace(/,/g, '');
	}
}
function nvl(num) {
	if (isNumeric(num)) {
		return num;
	} else {
		return 0;
	}
}
function isEmpty(obj) {
	if (obj == null || obj == undefined || obj == 'null' || obj == 'undefined' || obj == '') {
		return true;
	} else {
		return false;
	}
}
function isNumeric(n) {
	return !isNaN(parseFloat(n)) && isFinite(n);
}

// 01로 시작하는 핸드폰 및 지역번호와 050, 070 검증
// 원래 050은 0505 평생번호인가 그런데 보편적으로 050-5xxx-xxxx 로 인식함
// 0505-xxx-xxxx 라는 식으로 넣으면 통과할 수 없음. 그래서 경고창 띄울때 예시 넣는것이 좋음.
// -(하이픈)은 넣어도 되고 생략해도 되나 넣을 때에는 정확한 위치에 넣어야 함.
function isValidPhone(no) {
	if (no != '') {
		var regExp = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
		if (!regExp.test(no)) {
			commonAlert("잘못된 전화번호 형식입니다.");
			return false
		}
	}
	return true;
}
var setArrow = function(obj) {
	$(obj).closest('.selWrap').find('span.sel_tlt').html($(obj).html() + '<span class="arrow_sel">select화살표</span>');
}
var submit = {
	ajax : function(action, param, callbackFunction) {
		$.ajax({
			type : this.type,
			url : action,
			data : param,
			async : false,
			beforeSend : function(request) {
				showLoadingLayer();
				var csrfToken = $('meta[name="_csrf"]').attr('content') || '';
				var csrfName = $('meta[name="_csrf_header"]').attr('content') || '';
				if (this.type.toLowerCase() == 'post' && csrfName != '' && csrfToken != '') {
					request.setRequestHeader(csrfName, csrfToken);
				}
			},
			success : function(data) {
				hideLoadingLayer(true);
				callbackFunction(data);
			},
			error : function(xhr) {
				hideLoadingLayer(true);
				var data = $.parseJSON(xhr.responseText)
				alert(data.message);
			},
			complete : function(data) {
				hideLoadingLayer(true);
			}
		});
	},
	form : function(frmObj, action, param) {
		$(frmObj).submit(function(e) {
			submit.type = 'post';
			submit.ajax(action, $(frmObj).serialize(), function(data) {
				// console.log("data :" + data);
			});

			e.preventDefault(); // STOP default action
			e.unbind(); // unbind. to stop multiple form submit.
		});
	},
	secureSubmit : function(frmObj) {
//		var csrfToken = $("meta[name='_csrf']").attr("content");
//		var csrfName = $("meta[name='_csrf.parameter']").attr("content");
//		$(frmObj).append('<input type="hidden" name="' + csrfName + '" value="' + csrfToken + '" />');
		$(frmObj).removeAttr('onsubmit');
		$(frmObj).submit();
	},
	type : 'post'
}
var popup = {
	layer : function(action, pid, strParams, paging) {
		submit.type = 'post';
		submit.ajax(action, strParams, function(data) {
			if (paging) {
				$('.deliverySelWrap').html($(data).find('.deliverySelWrap').html());
				$('.pagingWrap').html($(data).find('.pagingWrap').html());
			} else {
				if (pid == 'pop_delivery_multi') {
					$('[name="multiDlv"]').attr('id', pid);
					$('[name="multiDlv"]').html(data);
				} else {
					$('[name="layerPopup"]').attr('id', pid);
					$('[name="layerPopup"]').html(data);
				}
				setLayerPopDirect(pid);
				// $("#layerPopup").html(data);
				// $("#layerPopup").html(data).click(function(){
				// init();
				// });
			}
		});
	},
	close : function(obj) {
		$(obj).closest('.fullpop').hide();
		$('.allWrap').removeClass('on');
		$('body').scrollTop(beforeScr);
		return;
	}
};
function openCommonPopup(actionUrl, params, pWidth, pHeight, winNm) {
	if (actionUrl != undefined) {
		var winName = "ssfPop";
		if (winNm != undefined) {
			winName = winNm;
		}
		if (pWidth == undefined) {
			pWidth = 680;
		}
		if (pHeight == undefined) {
			pHeight = 660;
		}
		openPopup(actionUrl, params, winName, pHeight, pWidth);
	}
}

//1단위 올림
var ceil10 = function(val) {
	return Math.ceil(nvl(val) * 0.1) * 10;
};

//10단위 올림
var ceil100 = function(val) {
	return Math.ceil(nvl(val) * 0.01) * 100;
};

//1단위 반올림
var round10 = function(val) {
	return Math.round(nvl(val) * 0.1) * 10;
};

// 10단위 반올림
var round100 = function(val) {
	return Math.round(nvl(val) * 0.01) * 100;
};

//10단위 절사
var floor100 = function(val) {
	return Math.floor(nvl(val) * 0.01) * 100;
};

var orderAlert = function(str) {
	commonAlert(str);
};

